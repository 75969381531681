import { useState } from "react";
import IconButton from '@mui/material/IconButton';
import { AssessmentOutlined, WidgetsOutlined, HelpOutlineOutlined, HomeOutlined } from "@mui/icons-material";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


export const MenuButton = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    return (
        <div className="pt-1">
            <IconButton href="help.html"  target="_blank" className="hover:text-white text-white"> 
                <HelpOutlineOutlined />
            </IconButton>
            <IconButton href="home" className="hover:text-white text-white"> 
                <HomeOutlined />
            </IconButton>
            <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="inherit">
                <WidgetsOutlined /> 
            </IconButton>
            <Menu
                id="menu-report"
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}>
                <a href="../onlineOfflineReport" className="hover:text-inherit">
                    <MenuItem>  
                        <AssessmentOutlined className="mr-1"></AssessmentOutlined>
                        Device Offline Report
                    </MenuItem>
                </a>

                <a href="../connectedDisconnectedReport" className="hover:text-inherit">
                    <MenuItem> 
                        <AssessmentOutlined className="mr-1"></AssessmentOutlined>
                        Device Disconnect Report
                    </MenuItem>
                </a>

            </Menu>
        </div>
    )
};

export default MenuButton;