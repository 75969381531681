
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
// import { AssessmentOutlined } from "@mui/icons-material";
// import { Link as RouterLink } from "react-router-dom";
import Grid from '@mui/material/Grid'



export function Home(props) {
  // const urlParams = new URLSearchParams(window.location.search);
  // const getStart = urlParams.get('from_ts');
  // const getEnd = urlParams.get("to_ts");
  // const icPath = "/incidentReport/?from_ts=" + getStart + "&to_ts=" + getEnd; 





  return (
      <>
          <AuthenticatedTemplate>
            {/* <ButtonGroup orientation="vertical">
              <Button component={RouterLink} to="/profile" variant="contained" color="primary">Request Profile Information</Button>
              <Button component={RouterLink} to="/profileUseMsalAuthenticationHook" variant="contained" color="primary">Request Access Token (using useMsalAuthentication hook)</Button>
              <Button component={RouterLink} to="/profileWithMsal" variant="contained" color="primary">Request Profile Information (using withMsal HOC)</Button>
              <Button component={RouterLink} to="/profileRawContext" variant="contained" color="primary">Request Profile Information (using raw context)</Button>
              <Button component={RouterLink} to={icPath} variant="contained" color="primary">Incident Report</Button>
            </ButtonGroup> */}
            <Typography variant="h5">
                <center className="text-header-bg dark:text-white">Welcome to the Crestron Incident Report Page</center>
            </Typography>
            <br/>
            <br/>
            {/* Report Selection Card in Home page */}

            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={2.5}></Grid>
              
              <Grid item xs={3}  className=" text-center text-gray-600 font-bold border-2 shadow-inner   hover:bg-blue-200" >
                <a href="../onlineOfflineReport" className="hover:text-sky-700 text-lg">
                <div>
                <img className="h-5 m-2 pt-0.5"  style={{ height: '200px', width: '96%' }}
                        alt="XiO Cloud Logo" src={require("../ui-components/xio_cloud.png")}/>
              {/* <AssessmentOutlined style={{ height: '15%', width: '40%' }}></AssessmentOutlined> */}
              </div>
              Device Offline Report
              </a>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} className=" text-center text-gray-600 font-bold  border-2 shadow-inner  hover:bg-blue-200">

                <a href="../connectedDisconnectedReport" className="hover:text-sky-700 text-lg">
                <div>
              <img className="h-5 m-2 pt-0.5"  style={{ height: '200px', width: '96%' }}
                      alt="XiO Cloud Logo" src={require("../ui-components/azure_iot.png")}/> 
              {/* <AssessmentOutlined style={{ height: '15%', width: '40%' }}></AssessmentOutlined> */}
              </div>
              Device Disconnect Report <br></br>
              </a>
              </Grid>
              <Grid item xs={2.5}></Grid>
          </Grid>

          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Typography variant="h6">
              <center>Please sign-in to see incident report.</center>
            </Typography>
          </UnauthenticatedTemplate>
      </>
  );
}