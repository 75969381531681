
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { infoObj } from './ui_constants';
import { MakeInfo } from './make_comps';
import { generateURLParams } from './parse_data';
import { MasterContext } from './make_comps';
import Button from '@mui/material/Button';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';



// Populating Column filter options
const getFilterOptions = (filterValues) => {
    let filterOptions = [];

    for (let i=0; i < filterValues.length; i++){
        // name - Display purpose
        // value - Value to be compared against Table cell value.
        filterOptions.push({'name': filterValues[i], 'value': filterValues[i]});
    }
    return filterOptions;
}

// Polulating Unique column filter options
const getUniqueFilterOptions = (filterValues) => {
    let filterOptions = [...getFilterOptions(filterValues)];
    filterOptions = filterOptions.map(JSON.stringify);
    filterOptions = new Set(filterOptions);
    filterOptions = Array.from(filterOptions).map(JSON.parse);
    return filterOptions;
}

// Multi-Select Filter Template used for few of Columns
const filterTemplate = (options, filterText, filterValues, isUniqueFilter=true) => {
    // TODO: GT, make dynamic filter
    return (
        <React.Fragment>
            <div className="mb-3 font-bold">{filterText}</div>
            <MultiSelect 
                filter
                maxSelectedLabels={1} 
                value={options.value} 
                options={options.filterOptions !== undefined ? options.filterOptions :
                        isUniqueFilter ? getUniqueFilterOptions(filterValues) : 
                        getFilterOptions(filterValues)}
                onChange={(e) => options.filterCallback(e.value)}
                optionLabel="name"
                placeholder="Any"
                className="p-column-filter" />
        </React.Fragment>
    );
};

// CSV file export
const exportCSV = (dt, selectionOnly) => {
    dt.current.exportFilename = "IncidentReport";
    dt.current.exportCSV({ selectionOnly });
};

// PDF export - NOT USED
// const exportPdf = () => {
//     import('jspdf').then((jsPDF) => {
//         import('jspdf-autotable').then(() => {
//             const doc = new jsPDF.default(0, 0);

//             doc.autoTable(exportColumns, filteredDevices);
//             doc.save('IncidentReport.pdf');
//         });
//     });
// };

const exportExcel = (filteredDevices, visibleColumns) => {
    import('xlsx').then((xlsx) => {
        // Formatting excel Data
        const excelData = () => {
            let tempExcelData = [];
            for (let i=0; i < filteredDevices.length;i++){
                let tempData = {};
                // Preserving and exporting only Visible Columns
                // Limitation : Column Re-ordering is not preserved in Excel export
                // TBD if that is important!
                // If required need to use onColReorder callback and track current Column order
                for(let j=0;j < visibleColumns.length; j++){
                    tempData[visibleColumns[j]['header']] = filteredDevices[i][visibleColumns[j]['field']];
                }
                tempExcelData.push(tempData);
            }
            return tempExcelData;
        }
        const worksheet = xlsx.utils.json_to_sheet(excelData());
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, 'IncidentReport');
    });
};

const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            module.default.saveAs(data, fileName  + EXCEL_EXTENSION);
        }
    });
};


// Custom Cell Body Templates
const deviceIdBodyTemplate = (rowData) => {
    const urlParams = generateURLParams();
    const deviceId = rowData.DeviceID;
    const link = "https://us3.datadoghq.com/logs?query=forwardername%3Aesbproc-log2dd%20%40DeviceInfo.DeviceId%3A"
                + deviceId + 
                "&cols=%40EventHubInfo.EnqTime%2C%40DeviceInfo.DeviceId%2C%40DeviceInfo.Model%2C%40EventHubInfo." +
                "Source%2C%40DeviceStatus%2C%40Parsed.EventHubInfo.Message.level%2C%40Parsed.EventHubInfo.Message.category%2C%40Parsed." +
                "EventHubInfo.Message.Operation%2C%40Parsed.EventHubInfo.Message.operationName&index=&messageDisplay=inline&stream_sort=time" +
                "%2Cdesc&viz=stream&from_ts=" + urlParams.fromTs + "&to_ts=" + urlParams.toTs + "&live=false"
    
    return (
        <a href={link} target="_blank" rel="noreferrer">{deviceId}</a>
    );
};

// Rendering Header
const renderHeader = (dt, filteredDevices, visibleColumns,  columnPickerOptions, setVisibleColumns, buttonStatus) => {
    const iconColor = `${buttonStatus ? 'text-white' : 'text-black'}`;
    const textColor = buttonStatus ? 'white' : 'black';
    const iconFont = {'fontSize': 25};


    
    // Updating selected Columns for Display
    const onColumnSelectionToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columnPickerOptions.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        setVisibleColumns(orderedSelectedColumns);
    };


    return (
        <div className="w-full grow shrink flex flex-wrap text-center justify-between sticky">
                {/* Column Picker */}
                <MultiSelect id='columnPicker' className='mx-2'
                    filter
                    panelStyle={{ width: '18rem'}}
                    scrollHeight='380px'
                    fixedPlaceholder
                    maxSelectedLabels={1} 
                    value={visibleColumns}
                    options={columnPickerOptions}
                    optionLabel="header"
                    onChange={onColumnSelectionToggle}
                    placeholder={"Columns"}
                />
            <div><h4 className="font-bold text-lg my-1">Device Table</h4></div>
            <div>
                <Button onClick={() => dt.current.reset()}
                        className={`hover:text-${textColor} text-${textColor} font-bold  border-black  dark:border-white` }
                        variant="outlined">
                    <FilterAltOffOutlinedIcon />Reset</Button>
                {/* Export Options */}
                <Tooltip title="Export CSV" arrow placement="top-end">
                    <IconButton  className={iconColor} onClick={() => exportCSV(dt, false)} data-pr-tooltip="CSV"> 
                        <span style={iconFont} className="p-button-icon p-c pi pi-file mx-0.5"></span>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Export Excel" arrow placement="top-end">
                    <IconButton className={iconColor} onClick={() => exportExcel(filteredDevices, visibleColumns)} data-pr-tooltip="XLS"> 
                        <span style={iconFont}  className="p-button-icon p-c pi pi-file-excel mx-0.5"></span>
                    </IconButton>
                </Tooltip>
                {/* Table info */}
                <MakeInfo infBody={infoObj.tbl} custClass="inline mx-0.5 h-5 w-5"/>
                {/* PDF Export - NOT USED */}
                {/* <IconButton className={iconColor} onClick={exportPdf} data-pr-tooltip="PDF"> 
                    <span style={iconFont}  className="p-button-icon p-c pi pi-file-pdf"></span>
                </IconButton> */}
            </div>
            
        </div>
    );
};

const renderTableColumn = (deviceColumns, visibleColumns) => {
    return visibleColumns.map((columnData) => (
        <Column 
            key={columnData.field}
            field={columnData.field} 
            columnKey={columnData.field}
            filterField={columnData.field}
            style={{ minWidth: '200px' }}
            {...deviceColumns[columnData.field]}
            sortable
            filter
            // filterPlaceholder is not applicable for Columns with filterElement
            filterPlaceholder={deviceColumns[columnData.field]['header']}
            >
        </Column>
    ));
}

// Default sorting OutageStatusTime in Descending order
const multiSortMeta = [{field: 'OutageStatusTime', order: -1}];

export function DeviceTable(props) {
    const masterContext = useContext(MasterContext);
    const masterObj = masterContext.masterObj;
    const buttonStatus = masterContext.buttonStatus;
    const [devices, setDevices] = useState([]);
    
    // Tracking filtered rows
    const [filteredDevices, setFilteredDevices] = useState([]);


    // Custom Filter Templates for Columns 

    const deviceIdFilterTemplate = (options) => {
        return filterTemplate(options, "Device Picker", masterObj[options.field], false);
    };

    const parentIdFilterTemplate = (options) => {
        return filterTemplate(options, "Parent CID", masterObj[options.field]);
    };

    const modelFilterTemplate = (options) => {
        return filterTemplate(options, "Model Picker", masterObj[options.field]);
    };

    const deviceVersionFilterTemplate = (options) => {
        return filterTemplate(options, "Version Picker", masterObj[options.field]);
    };

    const deviceStatusFilterTemplate = (options) => {
        options['filterOptions'] = [{'name': "Online", 'value': "Online"},
                                    {'name': "Offline", 'value': "Offline"}];
        return filterTemplate(options,  "Status Picker", masterObj[options.field]);
    };



    // Device Table column definitions

    const deviceColumns = useMemo (() => {
        let tempDeviceColumns = {
            'id': {
                header: "Row #",
                style: { minWidth: '110px' },
                defaultVisible: false
            },
            'DeviceID': {
                header: "Device ID",
                style: { minWidth: '250px' },
                filterMenuStyle: { width: '14rem' } ,
                filterElement: deviceIdFilterTemplate,
                showFilterMatchModes: false,
                body: deviceIdBodyTemplate,
                // TBD : make DeviceID frozen for Easy access to Digital Footprint
                // frozen: true,
                className: "hover:font-bold"
            },
            'DeviceName': {
                header: "Device Name",
                style: { minWidth: '250px' },
                defaultVisible: false,
            },
            'ParentCid': {
                header: "Parent Cid",
                style: { minWidth: '170px' },
                filterMenuStyle: { width: '14rem' } ,
                filterElement: parentIdFilterTemplate,
                showFilterMatchModes: false,
            },
            'Model': {
                header: "Model",
                style: { minWidth: '150px' },
                filterMenuStyle: { width: '14rem' },
                filterElement: modelFilterTemplate,
                showFilterMatchModes: false
            },
            'SerialNumber': {
                header: "Serial Number",
                defaultVisible: false
            },
            'DeviceVersion': {
                header: "Device Version",
                filterMenuStyle: { width: '14rem' }, 
                filterElement: deviceVersionFilterTemplate,
                showFilterMatchModes: false,
                style: { minWidth: '160px' }
            },
            'Category': {
                header: "Category",
                defaultVisible: false
            },
            'Manufacturer': {
                header: "Manufacturer",
                defaultVisible: false
            },
            'CcdDriverModel': {
                header: "CCD Driver Model",
                defaultVisible: false
            },
            'SystemVersion': {
                header: "Driver Version",
                defaultVisible: false
            },
            'IotSdkVersion': {
                header: "IoT SDK Version",
                defaultVisible: false
            },
            'Os': {
                header: "OS",
                defaultVisible: false
            },
            'AccountName': {
                header: "Account Name",
                style: { minWidth: '270px' }
            },
            // 'DisconnectTime': {
            //     header: "Disconnect Time"
            // },
            'OutageStatusTime': {
                header: "Incident Offline Time"
            },
            'AsOfStatus': {
                header: "Current Status",
                filterMenuStyle: { width: '14rem' },
                filterElement: deviceStatusFilterTemplate,
                showFilterMatchModes: false,
                style: { minWidth: '160px' }
            },
            'AsOfTime': {
                header: "Current Status Time",
                style: { minWidth: '220px' }
            },
            'LastFlipDur': {
                header: "Prev. Status Duration (min)",
                defaultVisible: false
            },
            'LastDur': {
                header: "Curr. Status Duration (min)",
                defaultVisible: false
            },
            'RecoveryStatus': {
                header: "Recovery Status"
            },
            'RecoveryTime': {
                header: "Recovery Time"
            },
            'RecoveryDuration': {
                header: "Time to Recovery (min)"
            },
            'ProbableCause': {
                header: "Probable Causes",
                defaultVisible: false
            },
            'GroupName': {
                header: "Group Name",
                defaultVisible: false
            },
            'OfflineAlertCount': {
                header: "Offline Alert Count" ,
                defaultVisible: false 
            },
            'ConnectedBackToApim': {
                header: "APIM Contact",
                style: { minWidth: '170px' }
            },
            'ApimRspStatus': {
                header: "APIM RSP Status",
                defaultVisible: false
            },
            'ApimRegion': {
                header: "APIM Region",
                defaultVisible: false
            },
            'LastApimContactTime': {
                header: "APIM Contact Time",
                defaultVisible: false
            },
            'FirstApimContactTime': {
                header: "Incident APIM Contact Time",
                defaultVisible: false
            },
            'ApimReconnectionDur': {
                header: "Disconnect Time to APIM (min)",
                defaultVisible: false
            },
            'Location': {
                header: "Region (Source=CosmosDB)"
            },
            'Email': {
                header: "Email" ,
                defaultVisible: false
            }
        }
        return tempDeviceColumns;
        // eslint-disable-next-line
    }, []);
    
    // Tracking column visibility
    const [visibleColumns, setVisibleColumns] = useState(() => {
        let defaultVisibleColumns = [];
        for (let columnField in deviceColumns){
            if (!('defaultVisible' in deviceColumns[columnField]) || (deviceColumns[columnField]['defaultVisible'])){
                defaultVisibleColumns.push({'field': columnField, 'header': deviceColumns[columnField]['header']});
            }
        }
        return defaultVisibleColumns;
    });

    // Populating Column picker options - ALL columns
    const columnPickerOptions = useMemo(() => {
        let tempColumnPickerOptions = [];
        for (let columnField in deviceColumns){
            tempColumnPickerOptions.push({
                'header': deviceColumns[columnField]['header'],'field': columnField
            });
            
        }
        return tempColumnPickerOptions;
        // eslint-disable-next-line
    }, []);


    // GT: dataTable use Reference
    const dt = useRef(null);
    
    // PDF Column definition - NOT USED
    // const cols = [
    //     { field: 'DeviceID', header: 'Device ID' },
    //     { field: 'DeviceName', header: 'Device Name' },
    // ];
    // const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const [filters] = useState({
        id: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]},
        // for MultiSelect Filter, need to use FilterMatchMode.IN
        DeviceID: { value: null, matchMode: FilterMatchMode.IN },
        DeviceName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ParentCid: { value: null, matchMode: FilterMatchMode.IN },
        Model: { value: null, matchMode: FilterMatchMode.IN },
        SerialNumber: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        DeviceVersion: { value: null, matchMode: FilterMatchMode.IN },
        AccountName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        // DisconnectTime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OutageStatusTime: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        AsOfStatus: { value: null, matchMode: FilterMatchMode.IN },
        AsOfTime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},        
        LastFlipDur: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        LastDur: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        RecoveryStatus: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        RecoveryTime: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        RecoveryDuration: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ProbableCause: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        GroupName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OfflineAlertCount: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ConnectedBackToApim: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ApimRspStatus: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ApimRegion: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        LastApimContactTime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        FirstApimContactTime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ApimReconnectionDur: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ApimReconnectionDur1: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Email: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Region: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        IotSdkVersion: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Os: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]}
    });
    const [loading, setLoading] = useState(true);
    // Loading the Table rows
    useEffect(() => {
        setDevices(masterContext.tableRows);
        setFilteredDevices(masterContext.tableRows);
        setLoading(false);
    }, [masterContext.tableRows]); // eslint-disable-line react-hooks/exhaustive-deps

    const header = renderHeader(dt, filteredDevices, visibleColumns,  columnPickerOptions, setVisibleColumns, buttonStatus);
    // Rendering Table columns
    const tableColumn = renderTableColumn(deviceColumns, visibleColumns)



    return (
        <DataTable  
            scrollable scrollHeight="800px" 
            tableStyle={{minWidth: "1800px"}} style={{'fontSize': '0.8em'}}
            showGridlines 
            value={devices} 
            ref={dt} 
            paginator reorderableColumns
            header={header} 
            rows={10}
            size="small"
            loading={loading}
            sortMode="multiple"
            exportFilename='IncidentReport'
            multiSortMeta={multiSortMeta}
            onValueChange={filteredData => {setFilteredDevices(filteredData);}}
            resizableColumns columnResizeMode="expand" 
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50, 100, 1000]}
            dataKey="id" selectionMode="checkbox" 
            filters={filters} filterDisplay="menu" 
            emptyMessage="No devices found." currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">   
            {tableColumn}
        </DataTable>
    );
}



export function IotDeviceTable(props) {
    const masterContext = useContext(MasterContext);
    const masterObj = masterContext.masterObj;
    const buttonStatus = masterContext.buttonStatus;
    const [devices, setDevices] = useState([]);
    
    // Tracking filtered rows
    const [filteredDevices, setFilteredDevices] = useState([]);


    // Custom Filter Templates for Columns 

    const deviceIdFilterTemplate = (options) => {
        return filterTemplate(options, "Device Picker", masterObj[options.field], false);
    };

    const parentIdFilterTemplate = (options) => {
        return filterTemplate(options, "Parent CID", masterObj[options.field]);
    };

    const modelFilterTemplate = (options) => {
        return filterTemplate(options, "Model Picker", masterObj[options.field]);
    };

    const deviceVersionFilterTemplate = (options) => {
        return filterTemplate(options, "Version Picker", masterObj[options.field]);
    };

    const deviceStatusFilterTemplate = (options) => {
        options['filterOptions'] = [{'name': "Online", 'value': "Online"},
                                    {'name': "Offline", 'value': "Offline"}];
        return filterTemplate(options,  "Status Picker", masterObj[options.field]);
    };



    // Device Table column definitions

    const deviceColumns = useMemo (() => {
        let tempDeviceColumns = {
            'id': {
                header: "Row #",
                style: { minWidth: '110px' },
                defaultVisible: false
            },
            'DeviceID': {
                header: "Device ID",
                style: { minWidth: '250px' },
                filterMenuStyle: { width: '14rem' } ,
                filterElement: deviceIdFilterTemplate,
                showFilterMatchModes: false,
                body: deviceIdBodyTemplate,
                // TBD : make DeviceID frozen for Easy access to Digital Footprint
                // frozen: true,
                className: "hover:font-bold"
            },
            'DeviceName': {
                header: "Device Name",
                style: { minWidth: '250px' },
                defaultVisible: false,
            },
            'ParentCid': {
                header: "Parent Cid",
                style: { minWidth: '170px' },
                filterMenuStyle: { width: '14rem' } ,
                filterElement: parentIdFilterTemplate,
                showFilterMatchModes: false,
            },
            'Model': {
                header: "Model",
                style: { minWidth: '150px' },
                filterMenuStyle: { width: '14rem' },
                filterElement: modelFilterTemplate,
                showFilterMatchModes: false
            },
            'SerialNumber': {
                header: "Serial Number",
                defaultVisible: false
            },
            'DeviceVersion': {
                header: "Device Version",
                filterMenuStyle: { width: '14rem' }, 
                filterElement: deviceVersionFilterTemplate,
                showFilterMatchModes: false,
                style: { minWidth: '160px' }
            },
            'Category': {
                header: "Category",
                defaultVisible: false
            },
            'Manufacturer': {
                header: "Manufacturer",
                defaultVisible: false
            },
            'CcdDriverModel': {
                header: "CCD Driver Model",
                defaultVisible: false
            },
            'SystemVersion': {
                header: "Driver Version",
                defaultVisible: false
            },
            'IotSdkVersion': {
                header: "IoT SDK Version",
                defaultVisible: false
            },
            'Os': {
                header: "OS",
                defaultVisible: false
            },
            'AccountName': {
                header: "Account Name",
                style: { minWidth: '270px' }
            },
            'OutageStatusTime': {
                header: "Incident Disconnect Time"
            },
            'AsOfStatus': {
                header: "Current Status",
                filterMenuStyle: { width: '14rem' },
                filterElement: deviceStatusFilterTemplate,
                showFilterMatchModes: false,
                style: { minWidth: '160px' }
            },
            'AsOfTime': {
                header: "Current Status Time",
                style: { minWidth: '220px' }
            },
            'LastFlipDur': {
                header: "Prev. Status Duration (min)",
                defaultVisible: false
            },
            'LastDur': {
                header: "Curr. Status Duration (min)",
                defaultVisible: false
            },
            'RecoveryStatus': {
                header: "Recovery Status"
            },
            'RecoveryTime': {
                header: "Recovery Time"
            },
            'RecoveryDuration': {
                header: "Time to Recovery (min)"
            },
            'GroupName': {
                header: "Group Name",
                defaultVisible: false
            },
            'ConnectedBackToApim': {
                header: "APIM Contact",
                style: { minWidth: '170px' }
            },
            'ApimRspStatus': {
                header: "APIM RSP Status",
                defaultVisible: false
            },
            'ApimRegion': {
                header: "APIM Region",
                defaultVisible: false
            },
            'LastApimContactTime': {
                header: "APIM Contact Time",
                defaultVisible: false
            },
            'Location': {
                header: "Region (Source=CosmosDB)"
            },
            'IotLocation': {
                header: "Region (Source=IotHub)"
            },
            'Email': {
                header: "Email" ,
                defaultVisible: false
            }
        }
        return tempDeviceColumns;
        // eslint-disable-next-line
    }, []);
    
    // Tracking column visibility
    const [visibleColumns, setVisibleColumns] = useState(() => {
        let defaultVisibleColumns = [];
        for (let columnField in deviceColumns){
            if (!('defaultVisible' in deviceColumns[columnField]) || (deviceColumns[columnField]['defaultVisible'])){
                defaultVisibleColumns.push({'field': columnField, 'header': deviceColumns[columnField]['header']});
            }
        }
        return defaultVisibleColumns;
    });

    // Populating Column picker options - ALL columns
    const columnPickerOptions = useMemo(() => {
        let tempColumnPickerOptions = [];
        for (let columnField in deviceColumns){
            tempColumnPickerOptions.push({
                'header': deviceColumns[columnField]['header'],'field': columnField
            });
            
        }
        return tempColumnPickerOptions;
        // eslint-disable-next-line
    }, []);


    // GT: dataTable use Reference
    const dt = useRef(null);
    
    // PDF Column definition - NOT USED
    // const cols = [
    //     { field: 'DeviceID', header: 'Device ID' },
    //     { field: 'DeviceName', header: 'Device Name' },
    // ];
    // const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const [filters] = useState({
        id: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]},
        // for MultiSelect Filter, need to use FilterMatchMode.IN
        DeviceID: { value: null, matchMode: FilterMatchMode.IN },
        DeviceName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ParentCid: { value: null, matchMode: FilterMatchMode.IN },
        Model: { value: null, matchMode: FilterMatchMode.IN },
        SerialNumber: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        DeviceVersion: { value: null, matchMode: FilterMatchMode.IN },
        AccountName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        // DisconnectTime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OutageStatusTime: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        AsOfStatus: { value: null, matchMode: FilterMatchMode.IN },
        AsOfTime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]}, 
        LastFlipDur: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        LastDur: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        RecoveryStatus: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        RecoveryTime: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        RecoveryDuration: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ProbableCause: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        GroupName: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OfflineAlertCount: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ConnectedBackToApim: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ApimRspStatus: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ApimRegion: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        LastApimContactTime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        FirstApimContactTime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ApimReconnectionDur: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Email: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Region: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        IotSdkVersion: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Os: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]}
    });
    const [loading, setLoading] = useState(true);
    // Loading the Table rows
    useEffect(() => {
        setDevices(masterContext.tableRows);
        setFilteredDevices(masterContext.tableRows);
        setLoading(false);
    }, [masterContext.tableRows]); // eslint-disable-line react-hooks/exhaustive-deps

    const header = renderHeader(dt, filteredDevices, visibleColumns,  columnPickerOptions, setVisibleColumns, buttonStatus);
    // Rendering Table columns
    const tableColumn = renderTableColumn(deviceColumns, visibleColumns)



    return (
        <DataTable  
            scrollable scrollHeight="800px" 
            tableStyle={{minWidth: "1800px"}} style={{'fontSize': '0.8em'}}
            showGridlines 
            value={devices} 
            ref={dt} 
            paginator reorderableColumns
            header={header} 
            rows={10}
            size="small"
            loading={loading}
            sortMode="multiple"
            exportFilename='IncidentReport'
            multiSortMeta={multiSortMeta}
            onValueChange={filteredData => {setFilteredDevices(filteredData);}}
            resizableColumns columnResizeMode="expand" 
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 25, 50, 100, 1000]}
            dataKey="id" selectionMode="checkbox" 
            filters={filters} filterDisplay="menu" 
            emptyMessage="No devices found." currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">   
            {tableColumn}
        </DataTable>
    );
}