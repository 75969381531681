import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
// Material-UI imports

// MSAL imports
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Sample app imports
import { PageLayout } from "./ui-components/PageLayout";
import { Logout } from "./pages/Logout";
import { GetDeviceData } from "./pages/IncidentReport";
import { GetIoTDeviceData } from "./pages/IotIncidentReport";
import Typography from "@mui/material/Typography";
import { Home } from "./pages/Home";

import { generateURLParams } from './pages/incidentReportPages/parse_data';
import { RouteGuard } from "./RouteGuard";
import {groups} from "./authConfig"


// Class-based equivalents of "Profile" component
// import { ProfileWithMsal } from "./pages/ProfileWithMsal";
// import { ProfileRawContext } from "./pages/ProfileRawContext";
// import { ProfileUseMsalAuthenticationHook } from "./pages/ProfileUseMsalAuthenticationHook";





function App({ pca }) {
  const urlparams = generateURLParams();

  const [startobj,] = useState(urlparams.startObj);
  const [endobj,] = useState(urlparams.endObj);
  const [aotobj,] = useState(urlparams.aotObj); 

  

  useEffect(() => {
      // Storing start, end & aot time.
      // Aot time will be regenerated everytime the page is refreshed.
      // But, start and end will remain same.
      localStorage.setItem('startTimeObj', JSON.stringify(startobj));
      localStorage.setItem('endTimeObj', JSON.stringify(endobj));
      localStorage.setItem('aotTimeObj', JSON.stringify(aotobj));
  });


  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <PageLayout>
          {/* <Grid container justifyContent="center"> */}
            <PageContent />
          {/* </Grid> */}
        </PageLayout>
      </MsalProvider>
    </ClientSideNavigation>
  );
}

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */ 
function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

function PageContent() {
  return (
    <>
    <AuthenticatedTemplate>
      {/* Display Pages only if Authenticated */}
      <Pages />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
        <Typography variant="h6">
          <center>Please sign-in to see incident report.</center>
        </Typography>
      </UnauthenticatedTemplate>

  </>
  )
}

function Pages() {
  return (
    <Routes>
      {/* <Route path="/profile" element={<Profile />} />
      <Route path="/profileWithMsal" element={<ProfileWithMsal />} />
      <Route path="/profileRawContext" element={<ProfileRawContext />} />
      <Route path="/profileUseMsalAuthenticationHook" element={<ProfileUseMsalAuthenticationHook />} /> */}
      <Route path="/"  element={<Home /> } />
      <Route path="/home"  element={<Home /> } />
      <Route path="/onlineOfflineReport"  element={
        <RouteGuard requiredGroups={[groups.EsOps, groups.CrestronSupportGroup]}>
            <GetDeviceData />
        </RouteGuard> }
      />
      <Route path="/connectedDisconnectedReport"  element={
        <RouteGuard requiredGroups={[groups.EsOps, groups.CrestronSupportGroup]}>
            <GetIoTDeviceData />
        </RouteGuard> } 
      />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  )
}

export default App;
