import React, { useContext } from 'react';
import './loading.css';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { MasterContext } from './make_comps';

export function Loadin() {
  return (
    <div className="loading">
      <div className="loading-text">
        <span className="loading-text-words">L</span>
        <span className="loading-text-words">O</span>
        <span className="loading-text-words">A</span>
        <span className="loading-text-words">D</span>
        <span className="loading-text-words">I</span>
        <span className="loading-text-words">N</span>
        <span className="loading-text-words">G</span>
      </div>
    </div>
  )
}

export function MiniLoadin() {

  const masterContext = useContext(MasterContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    masterContext.setAlrtOpen(false);
  };

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={masterContext.miniLoaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={masterContext.alrtOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          Data Successfully Updated!
        </Alert>
      </Snackbar>
    </div>
  );
}
