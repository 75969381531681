import { thirdPartyDeviceModel, recoveryTimeBinFrequency } from "./ui_constants";

export const aotObj = new Date(Date.now());


export function ParseData(jsonData, computeTableData=true) {
    const masterObj = {
        // Default schema
        "ReqID": [], "DeviceID": [],  "OutageStatusTime": [],
        "OutageStatus": [], "AsOfTime": [], "RecoveryTime": [],"AsOfStatus": [],
        "LastFlipDur": [], "LastDur": [],"RecoveryStatus": [], "RecoveryDuration": [],
        "ConnectedBackToApim": [], "ApimRspStatus":[], "ApimRegion":[], "LastApimContactTime": [],
        'IotSdkVersion':[], 'Os': [], 'IotLocation': [],
        // Account Info Tuple
        "AccountId": [], "AccountName": [], "Email": [],
        // Device Info Tuple
        "DeviceIdDup": [], "DeviceName": [], "DeviceVersion": [], "Model": [], "SerialNumber": [], "Location": [],
        "MacAddress": [], "Manufacturer":[], "ParentCid": [], "CloudOwnedDevice": [], "ApimPufVersion": [],
        "Category": [], "CcdDriverModel":[], "SystemVersion": [],
        //  "ApimType":[],
        // Group Info Tuple
        "GroupId":[], "GroupName": [], "GroupType": [],
        // ClaimStateInfo Tuple
        // "PrivateIp": [], "PublicIp": [], "LastNewRegDate": [], "LastClaimedDate": [], "LastStateName": [],
        // "ApimLastContact": [], "ClaimedRegion": []
    };
    const tableRows = [];
    const devArr = jsonData;
    const keys = Object.keys(masterObj);

    for (let i = 1; i < devArr.length - 1; i++) {
        // const devEach = devArr[i].split(",");
        let devEach = devArr[i];

        // first replace 2 quotes with || and then remove all quotes.
        // else all the quotes will be lost
        // then replace || back with quotes.
        devEach = devEach.replace(/""/g, '<1SEP1>');
        devEach = devEach.replace(/"/g, '');
        devEach = devEach.replace(/<1SEP1>/g, '"');
        devEach = devEach.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
        let newDevArr = [];
        let rowData = {id: i};

        for(let i = 0; i < devEach.length; i++) {
          // replace any quotes
          let tmpStr = devEach[i];
          tmpStr = tmpStr.replace(/^"+/,'').replace(/"+/g,'"');
          if ((tmpStr.match(/"/g) || []).length === 1) {
            tmpStr = tmpStr.replace(/"$/,'');
          }
          tmpStr = tmpStr.replace(/null/, '');
          tmpStr = tmpStr.replace(/Outage/, 'Incident');
          tmpStr = tmpStr.replace(/StatusSpam/, 'Status Spam');
          newDevArr.push(tmpStr);
        }
        if (computeTableData){
            keys.forEach((key, idx) => {
                masterObj[key].push(newDevArr[idx]);
                formatTableAttribute(key, newDevArr[idx], rowData);
            });
            tableCompute(rowData);
            // TODO : Handle in SB App
            if (rowData['ParentCid'] !== "" || thirdPartyDeviceModel.includes(rowData['Model'])){
                rowData['LastApimContactTime'] = "NA"
                rowData['ApimRegion'] = "NA"
                rowData['ApimRspStatus'] = "NA"
                // rowData['ApimType'] = "NA"
                rowData['ConnectedBackToApim'] = "NA"
                rowData['FirstApimContactTime'] = "NA"
                rowData['ApimReconnectionDur'] = "NA"
            }
            tableRows.push(rowData);
        }
        else {
            keys.forEach((key, idx) => {
                masterObj[key].push(newDevArr[idx]);
            });
        }

    }
    // console.log(tableRows);
    return [masterObj, tableRows];
}


function formatTableAttribute(keyName, keyValue, rowData){;
    // OPSCTR-404: Here we will convert duration of seconds to
    // minutes.
    // LastFlipDur, LastDur, RecoveryDuration needs to be converted
    // to minutes.
    if (  (keyName === 'LastFlipDur') ||
            (keyName === 'LastDur') ||
            (keyName === 'RecoveryDuration') ||
            (keyName === 'ApimReconnectionDur')) {
        rowData["src" + keyName] = keyValue;
            if (keyValue === ''){
                keyValue = null;
            }
            else {
                keyValue = parseFloat(keyValue / 60).toFixed(2);
                let keyValueSplit = keyValue.toString().split(".");
                if (parseInt(keyValueSplit[1][0]) === 0 && parseInt(keyValueSplit[1][1]) === 0){
                    keyValue = Math.round(keyValue)
            }
        }
    }
    // OPSCTR-401: Need to strip off millisecs and timezone
    else if ((keyName === 'OutageStatusTime') ||
        (keyName === 'AsOfTime') ||
        (keyName === 'RecoveryTime') ||
        (keyName === 'LastApimContactTime') ) {
        // storing Raw data for Filtering Purpose.
        rowData["src" + keyName] = new Date(keyValue).getTime();
        keyValue = keyValue.split('+')[0];
        //keyValue = keyValue.substring(0, keyValue.length - 1);
    }
    // TODO : Handle in SB App
    else if(keyName === 'RecoveryStatus') {
        if (keyValue === 'Yes') {
        keyValue = 'Recovered';
        } else if (keyValue === 'No') {
        keyValue = 'Not Recovered';
        }
    }
    rowData[keyName] = keyValue;
}

function tableCompute(rowData){
    // checking if Device is Ancillary or not
    if (rowData["ParentCid"] === ""){
        rowData["IsAncillary"] = false
    }
    else {
        rowData["IsAncillary"] = true
    }

    // Updating RecoveryTimeWindow values
    if (rowData["RecoveryStatus"] === "Recovered"){
        [rowData['RecoveryTimeBin'], rowData['RecoveryTimeBinIndex']] = getTimeWindow(rowData["RecoveryDuration"]);
    }
    else {
        [rowData['RecoveryTimeBin'], rowData['RecoveryTimeBinIndex']] = ["NA", -1]
    }
    
    [rowData['IncidentDisconnectToApimTimeBin'], ] = 
        rowData["ApimReconnectionDur"] !== "NA" ?
         getTimeWindow(rowData["ApimReconnectionDur"]): ["NA", ];
}



/**
 * Function to calculate Time window
 * @param {float} timeDuration 
 * @returns {string} Time Window
 */

export function getTimeWindow(timeDuration){
    let timeWindow = "";
    switch(true){
        case (timeDuration <= 1):
            timeWindow = "< 1 min"
            break;
        case (timeDuration > 1 && timeDuration <= 5):
            timeWindow = "1 - 5 min";
            break;
        case (timeDuration > 5 && timeDuration <= 10):
            timeWindow = "5 - 10 min";
            break;
        case (timeDuration > 10 && timeDuration <= 15):
            timeWindow = "10 - 15 min";
            break;
        case (timeDuration > 15 && timeDuration <= 30):
            timeWindow = "15 - 30 min";
            break;
        case (timeDuration > 30 && timeDuration <= 60):
            timeWindow = "30 min - 1 hour";
            break;
        default:
            timeWindow = "> 1 hour";
            break;
    }
    return [timeWindow, recoveryTimeBinFrequency.indexOf(timeWindow)]
}




export function parseDonutData(parsedJson, tableData=[]) {
    const outOffs = parsedJson["DeviceID"].length;
    const outOns = 25325235;
    let aotOffs = 0;
    let aotOns = 0;
    let recOnce = 0;
    let notRecovered = 0;
    let topCustomerStats = {};
    let topModelStats = {};
    let topDeviceVersionStats = {};
    let customerOptions = {};
    let modelOptions = {};
    let deviceVersionOptions = {};
    
    let topRecCustomerStats = {};
    let topRecModelStats = {};
    let topRecDeviceVersionStats = {};

    // let topNotRecCustomerStats = {};
    // let topNotRecModelStats = {};
    // let topNotRecDeviceVersionStats = {};
    // let topCustomerStatsPercentage = {};
    // let topModelStatsPercentage = {};
    // let topDeviceVersionStatsPercentage = {};

    let topCustomerRecPercentage = {};
    let topModelRecPercentage = {};
    let topDeviceRecStatsPercentage = {};

    // let topCustomerNotRecPercentage = {};
    // let topModelNotRecPercentage = {};
    // let topDeviceNotRecStatsPercentage = {};

    let apimContactStats = {'Yes':0, 'No':0, 'NA': 0};
    // let recoveryTimeStats = {};
    // let recoveryTimeStep = 0;
    let maxRecoveryTimeRecordedMin = 0;
    // let recoveryTimeSpread = [];
    let recoveryTimeDurationSpread = {};
    // let incidentTimeRecoveryDurationSpread = {};
    // Time Bin Data
    let affectedTimeStatsMins = Object.fromEntries(recoveryTimeBinFrequency.map(timeBin => [timeBin, 0]))
    let recoveryTimeStatsMins = Object.fromEntries(recoveryTimeBinFrequency.map(timeBin => [timeBin, 0]))
    let notRecoveryTimeStatsMins = Object.fromEntries(recoveryTimeBinFrequency.map(timeBin => [timeBin, 0]))
    let apimTimeStatsMins = Object.fromEntries(recoveryTimeBinFrequency.map(timeBin => [timeBin, 0]));
    let lastapimTimeStatsMins = Object.fromEntries(recoveryTimeBinFrequency.map(timeBin => [timeBin, 0]));
    // let notRecoveredDeviceCid = [];
    let ancillaryDeviceStats = {};
    let ancillaryDeviceOptions = [];
    // let notRecoveredAncillaryChildDeviceCount = 0;
    // let otherParentCid = [];

   
    if (parsedJson["RecoveryDuration"].length > 0){
        maxRecoveryTimeRecordedMin = parsedJson["RecoveryDuration"].reduce((max, temp) => parseFloat(temp) > parseFloat(max)? temp : max)/60;
    }

    parsedJson["RecoveryTimeBin"] = [];
    parsedJson["RecoveryTimeBinIndex"] = [];

    // generic method to fetch the top N of an object
    const pickHighest = (obj, num = 1) => {
        const requiredObj = {};
        const requiredObjList = [];
        // Displaying items even if total count is less than 10
        // if(num > Object.keys(obj).length){
        //    return false;
        // };
        Object.keys(obj).sort((a, b) => obj[b] - obj[a]).forEach((key, ind) =>
        {
           if(ind < num){
              requiredObj[key] = obj[key];
              requiredObjList.push({[key]: "NA", 'name': key})
           }
        });
        return [requiredObj, requiredObjList];
     };


    for (let i = 0; i < parsedJson["DeviceID"].length; i++) {
        let recoveryTimeBin = "";
        let recoveryTimeBinIndex = -1;
        let apimTimeBin = "";
        let lastapimTimeBin = "";
        let apimContactDuration = 0;
        let lastapimContactDuration = 0;
        try{
            apimContactDuration = tableData[i]['ApimReconnectionDur'];
        }
        catch {
            apimContactDuration = undefined;
        }
        try{
            lastapimContactDuration = tableData[i]['LastApimContactDuration'];
        }
        catch {
            lastapimContactDuration = undefined;
        }
        
        let parentCid = parsedJson["ParentCid"][i];

        if (parentCid !== ""){
            if (ancillaryDeviceStats.hasOwnProperty(parentCid)){
                ancillaryDeviceStats[parentCid] += 1;
            }
            else {
                ancillaryDeviceStats[parentCid] = 1;
            }
        }

        if (parsedJson["AsOfStatus"][i].includes("Offline") || parsedJson["AsOfStatus"][i].includes("offline")) {
            aotOffs += 1;
        }
        else if (parsedJson["AsOfStatus"][i].includes("Online") || parsedJson["AsOfStatus"][i].includes("online")) {
            aotOns += 1;
        }


        if (apimContactDuration !== undefined && apimContactDuration !== "NA" && apimContactDuration !== null){
            [apimTimeBin, ] = getTimeWindow(apimContactDuration);
            apimTimeStatsMins[apimTimeBin] += 1
        }
        if (lastapimContactDuration !== undefined && lastapimContactDuration !== "NA"){
            [lastapimTimeBin, ] = getTimeWindow(lastapimContactDuration);
            lastapimTimeStatsMins[lastapimTimeBin] += 1
        }


        if (parsedJson["RecoveryStatus"][i].includes("Yes")) {
            // Calculating RecoveryTime in Minutes
            let recoveryTime = (parseFloat(parsedJson["RecoveryDuration"][i])/60).toFixed(2);
            let timeValueSplit = recoveryTime.toString().split(".")
            if (parseInt(timeValueSplit[1][0]) === 0 && timeValueSplit[1][1] === 0){
                recoveryTime = Math.round(recoveryTime);
            }
            recOnce += 1;
            // recoveryTimeStats[(Math.ceil(recoveryTime/recoveryTimeStep)) * recoveryTimeStep] += 1
            // recoveryTimeSpread.push(recoveryTime);
            recoveryTimeDurationSpread[recoveryTime] = parsedJson["RecoveryTime"][i];
            // incidentTimeRecoveryDurationSpread[recoveryTime] = parsedJson["OutageStatusTime"][i];
            // Calculating Devices count per Time Window of Recovery.
            [recoveryTimeBin, recoveryTimeBinIndex] = getTimeWindow(recoveryTime);
            recoveryTimeStatsMins[recoveryTimeBin] += 1
        }
        else{
            notRecovered += 1;
            recoveryTimeBin = "NA";
            // notRecoveredDeviceCid.push(parsedJson["DeviceID"][i])
            
        }
        // Updating Recovery Time Bin value
        parsedJson["RecoveryTimeBin"][i] = recoveryTimeBin;
        parsedJson["RecoveryTimeBinIndex"][i] = recoveryTimeBinIndex;
        // Record the customer count
        if( topCustomerStats.hasOwnProperty(parsedJson['AccountName'][i]) ) {
            topCustomerStats[parsedJson['AccountName'][i]] += 1;
        } else {
            topCustomerStats[parsedJson['AccountName'][i]] = 1;
        }
        // Record the Device Version count
        if( topDeviceVersionStats.hasOwnProperty(parsedJson['DeviceVersion'][i]) ) {
            topDeviceVersionStats[parsedJson['DeviceVersion'][i]] += 1;
        } else {
            topDeviceVersionStats[parsedJson['DeviceVersion'][i]] = 1;
        }
        // Record the Device Model count
        if( topModelStats.hasOwnProperty(parsedJson['Model'][i]) ) {
            topModelStats[parsedJson['Model'][i]] += 1;
        } else {
            topModelStats[parsedJson['Model'][i]] = 1;
        }

        // Record the Recovered customer count
        if (parsedJson["RecoveryStatus"][i].includes("Yes")) {
            if( topRecCustomerStats.hasOwnProperty(parsedJson['AccountName'][i]) ) {
                topRecCustomerStats[parsedJson['AccountName'][i]] += 1;
            } else {
                topRecCustomerStats[parsedJson['AccountName'][i]] = 1;
            }
            // Record the  Recovered Device Version count
            if( topRecDeviceVersionStats.hasOwnProperty(parsedJson['DeviceVersion'][i]) ) {
                topRecDeviceVersionStats[parsedJson['DeviceVersion'][i]] += 1;
            } else {
                topRecDeviceVersionStats[parsedJson['DeviceVersion'][i]] = 1;
            }
            // Record the Recovered Device Model count
            if( topRecModelStats.hasOwnProperty(parsedJson['Model'][i]) ) {
                topRecModelStats[parsedJson['Model'][i]] += 1;
            } else {
                topRecModelStats[parsedJson['Model'][i]] = 1;
            }
        }
        // else {
        //     if( topNotRecCustomerStats.hasOwnProperty(parsedJson['AccountName'][i]) ) {
        //         topNotRecCustomerStats[parsedJson['AccountName'][i]] += 1;
        //     } else {
        //         topNotRecCustomerStats[parsedJson['AccountName'][i]] = 1;
        //     }
        //     // Record the  Recovered Device Version count
        //     if( topNotRecDeviceVersionStats.hasOwnProperty(parsedJson['DeviceVersion'][i]) ) {
        //         topNotRecDeviceVersionStats[parsedJson['DeviceVersion'][i]] += 1;
        //     } else {
        //         topNotRecDeviceVersionStats[parsedJson['DeviceVersion'][i]] = 1;
        //     }
        //     // Record the Recovered Device Model count
        //     if( topNotRecModelStats.hasOwnProperty(parsedJson['Model'][i]) ) {
        //         topNotRecModelStats[parsedJson['Model'][i]] += 1;
        //     } else {
        //         topNotRecModelStats[parsedJson['Model'][i]] = 1;
        //     }
        // }
        // stats for contact APIM
        if (parsedJson['ParentCid'][i] !== "" || thirdPartyDeviceModel.includes(parsedJson["Model"][i])){
            apimContactStats['NA'] += 1;
        }
        else if (parsedJson["ConnectedBackToApim"][i].includes("Yes")) {
            apimContactStats['Yes'] += 1;

        } else if (parsedJson["ConnectedBackToApim"][i].includes("No")) {
            apimContactStats['No'] += 1;
        }
    }

    let deviceCurrentlyAffected = outOffs;
    let recoveryPauseTimeWindow = false;
    for (let timewindow in notRecoveryTimeStatsMins){
        notRecoveryTimeStatsMins[timewindow] = deviceCurrentlyAffected - recoveryTimeStatsMins[timewindow];
        deviceCurrentlyAffected = notRecoveryTimeStatsMins[timewindow];
        affectedTimeStatsMins[timewindow] = notRecoveryTimeStatsMins[timewindow] + recoveryTimeStatsMins[timewindow];
        // Stopping TimeWindow cumulation for Recovery device count.
        if (affectedTimeStatsMins[timewindow] === notRecovered){
            let tempAffectedTimeStat = affectedTimeStatsMins[timewindow];
            delete affectedTimeStatsMins[timewindow];     
            if (!recoveryPauseTimeWindow){
                let endTimeWindow = "";
                recoveryPauseTimeWindow = true;
                if (notRecovered === 0){
                    endTimeWindow = timewindow;
                }
                else {
                    endTimeWindow = recoveryTimeBinFrequency[recoveryTimeBinFrequency.length - 1];
                }  
                endTimeWindow = endTimeWindow.replace("<", "").replace(">", "");
                endTimeWindow = "> " + endTimeWindow;
                if (endTimeWindow.includes("-")){
                    endTimeWindow = endTimeWindow.split("-")[0] + (endTimeWindow.includes("min") ? (endTimeWindow.includes("hour") ? "" : "min" ) : 'hour');
                }
                affectedTimeStatsMins[endTimeWindow] = tempAffectedTimeStat;
            }
                   
        }
    }

    [topCustomerStats, customerOptions] = pickHighest(topCustomerStats, 10);
    [topDeviceVersionStats, deviceVersionOptions] = pickHighest(topDeviceVersionStats, 10);
    [topModelStats, modelOptions] = pickHighest(topModelStats, 10);

    // Old charts presenting Top recovered devices count.
    // topRecCustomerStats = pickHighest(topRecCustomerStats, 10);
    // topRecDeviceVersionStats = pickHighest(topRecDeviceVersionStats, 10);
    // topRecModelStats = pickHighest(topRecModelStats, 10);
    
    for (let customer in topCustomerStats){
        // topCustomerStatsPercentage[customer] =  parseFloat((topCustomerStats[customer]/outOffs) * 100).toFixed(2).replace(/\.0+$/,'');
        topCustomerRecPercentage[customer] = topRecCustomerStats[customer] === undefined ? 0: parseFloat((topRecCustomerStats[customer]/topCustomerStats[customer]) * 100).toFixed(2).replace(/\.0+$/,'');
        // topCustomerNotRecPercentage[customer] = topNotRecCustomerStats[customer] === undefined ? 0:parseFloat((topNotRecCustomerStats[customer]/topCustomerStats[customer]) * 100).toFixed(2).replace(/\.0+$/,'');
    }
    for (let model in topModelStats){
        // topModelStatsPercentage[model] = parseFloat((topModelStats[model]/outOffs) * 100).toFixed(2).replace(/\.0+$/,'') ;
        topModelRecPercentage[model] = topRecModelStats[model] === undefined ? 0: parseFloat((topRecModelStats[model]/topModelStats[model]) * 100).toFixed(2).replace(/\.0+$/,'') ;
        // topModelNotRecPercentage[model] = topNotRecModelStats[model] === undefined ? 0 : parseFloat((topNotRecModelStats[model]/topModelStats[model]) * 100).toFixed(2).replace(/\.0+$/,'') ;
    }
    for (let version in topDeviceVersionStats){
        // topDeviceVersionStatsPercentage[version] =  parseFloat((topDeviceVersionStats[version]/outOffs) * 100).toFixed(2).replace(/\.0+$/,'') ;
        topDeviceRecStatsPercentage[version] = topRecDeviceVersionStats[version] === undefined ? 0: parseFloat((topRecDeviceVersionStats[version]/topDeviceVersionStats[version]) * 100).toFixed(2).replace(/\.0+$/,'') ;
        // topDeviceNotRecStatsPercentage[version] = topNotRecDeviceVersionStats[version] === undefined ? 0: parseFloat((topNotRecDeviceVersionStats[version]/topDeviceVersionStats[version]) * 100).toFixed(2).replace(/\.0+$/,'') ;
    }

    // Top 10 Parents with Child Devices that are Affected
    [ancillaryDeviceStats,] = pickHighest(ancillaryDeviceStats, 10);
    const donutData = {
        "OutageOff": outOffs, "OutageOn": outOns,
        "AotOff": aotOffs, "AotOn": aotOns,
        "Recovered": recOnce, "NotRecovered": notRecovered,
        "topCustomerStats": topCustomerStats, 
        "topDeviceVersionStats":topDeviceVersionStats,
        "topModelStats":topModelStats,
        "customerOptions": customerOptions,
        "modelOptions": modelOptions,
        "deviceVersionOptions": deviceVersionOptions,
        "topRecCustomerStats": topRecCustomerStats, 
        "topRecDeviceVersionStats":topRecDeviceVersionStats,
        "topRecModelStats":topRecModelStats,
        // "topCustomerStatsPercentage": topCustomerStatsPercentage,
        // "topModelStatsPercentage": topModelStatsPercentage,
        // "topDeviceVersionStatsPercentage": topDeviceVersionStatsPercentage,
        "apimContactStats":apimContactStats,
        "topCustomerRecPercentage": topCustomerRecPercentage,
        "topModelRecPercentage": topModelRecPercentage,
        "topDeviceRecStatsPercentage": topDeviceRecStatsPercentage,
        // "topCustomerNotRecPercentage": topCustomerNotRecPercentage ,
        // "topModelNotRecPercentage": topModelNotRecPercentage ,
        // "topDeviceNotRecStatsPercentage": topDeviceNotRecStatsPercentage,
        // "recoveryTimeStats": recoveryTimeStats,
        // "recoveryTimeSpreadPoints": recoveryTimeSpreadPoints,
        "recoveryTimeStatsMins": recoveryTimeStatsMins,
        "notRecoveryTimeStatsMins": notRecoveryTimeStatsMins,
        "affectedTimeStatsMins": affectedTimeStatsMins,
        "recoveryTimeDurationSpread": recoveryTimeDurationSpread,
        // "incidentTimeRecoveryDurationSpread": incidentTimeRecoveryDurationSpread,
        "maxRecoveryTimeRecordedMin": maxRecoveryTimeRecordedMin,
        "anciallaryDeviceInfo": {
            "ancillaryDeviceStats": ancillaryDeviceStats,
            // "childDeviceCount": notRecoveredAncillaryChildDeviceCount,
            // "otherParentCids": otherParentCid
        },
        "apimTimeStatsMins": apimTimeStatsMins,
        "lastapimTimeStatsMins": lastapimTimeStatsMins,
        "ancillaryDeviceOptions": ancillaryDeviceOptions                             
    };
    // console.log(donutData);
    return donutData;
}




