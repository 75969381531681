export const thirdPartyDeviceModel = ["SonyConnectedDisplay",
    "PhilipsConnectedDisplay",
    "PanasonicConnectedDisplay",
    "EpsonConnectedDisplay",
    "SharpConnectedDisplay",
    "LegrandConnectedPDU",
    "LGEConnectedDisplay",
    "SamsungConnectedDisplay",
    "ChristieDigitalConnectedDisplay",
    "OptomaConnectedDisplay",
    "BarcoConnectedDisplay",
    "AcerConnectedDisplay",
    "SMARTBoardConnectedDisplay"]


export const recoveryTimeBinFrequency = ['< 1 min', '1 - 5 min', '5 - 10 min', '10 - 15 min', '15 - 30 min', '30 min - 1 hour', '> 1 hour'];


export const infoObj = {
    "offvOn": "The Cumulative Offline vs Cumulative Online count. " +
        "This displays the data on all claimed devices that are " +
        "registered/deregistered to Crestron.",

    "recovered": "The Offline vs Recovered Devices count. This bubble displays data on devices that were " +
        "Offline in the incident window but came back online outside of incident window. This recovered count is " +
        "displayed against the devices that were still offline outside the incident window.",


    "apim": "Donut chart presenting distribution of Devices that made APIM Contact vs Not Contacted after Device went Offline",

    "probC": "The Probable Causes for why the device(s) went offline. This bubble displays the amount of " +
        "devices that went offline either due to some incident or status spamming.\n" +
        "Cause = 'StatusSpam': if Count of Device Flip Event between AsOftime-20Min till AsOfTime >= 10" +
        "Cause = 'HbSpam': if Count of HB msg >= 10 in Last 20 min.",

    "slidr": "The Time Picker. This picker helps you go back in time by decreasing the Last Status time. " +
        "The time difference range is (in hours, minutes and seconds) between the End time and Last Status Time. " +
        "To use it, select the needed hour, minutes and seconds, and click 'Go', and watch the values on the dashboard change. " +
        "The Last Status Time can go backwards only from the Report Generated Time.",


    "lineChrt": "The Line Area Chart that displays a trend for the amount of devices that were Offline versus Online " +
        "over the duration from the End Time to the Last Updated Time.",

    "apimContactTimeBin": "The Stacked Bar chart presents Number of Devices in" + 
        " each discrete Time duration measuring APIM Contact time. The APIM contact time here is relative from the Time device went offline. " +
        " * Incident Offline to APIM - means Data representing Last APIM contact time before the Device came online from the Incident" +
         " Offline during Incident window. * Last Offline to APIM - means Data representing Last APIM contact time before the Device came online from the Last" +
         " Offline (after Device recovered from the Incident Offline and came Online)" ,

    "deviceRecoveryTimeBinChart": "The Stacked Bar chart presents Number of Affected, Recovered and Not Recovered devices in" + 
        " each discrete Time duration measuring recovery performance. The Recovery Duration here is relative from the Time device went offline",

    "deviceRecoveryTimeLine": "The Scatter Chart presents Devices plotted for Recovery Time vs Recovery Duration." +
        "To zoom in to the chart use Mouse scroll in/out (or) Mouse drag to create rectangular area of selection on Chart."+ 
        "Click on FILTER button to apply cross filter to the Report for visibile devices in chart",

    "placeholdr": "Chart Coming Soon!",

    "tbl": "This table displays all the necessary info on the devices. Some useful features include single/multiple sorting by a " +
        "field (ascending or descending), filtering by single/multiple column fields (for Mulitple sorting, press Ctrl + X to include " +
        "more than one column in sorting), and hiding columns." +
        "To download the data from the table CSV/Excel format, click the download button on the Table top right corner." +
        "More information on the table columns: (1) Device ID: A unique alphanumeric ID assigned to each crestron device." +
        "(2) Incident Status Time: The timestamp for status of a given device in between start and end time of the incident window." +
        "(3) Incident Status: The status of a given device in between start and end time of the incident window." +
        "(4) Last Updated Time: The timestamp for status of a given device in between end time and the current time outside the incident window." +
        "(5) Last Updated Status: The status of a given device in between end time and the current time outside the incident window." +
        "(6) Last Duration: The duration for which a given device was offline or online." +
        "(7) Last Flip Duration: The duration for which a device was online/offline before it switched its status."
}

export const iotinfoObj = {
    "discDevice": "Total number of devices identified to have Disconnected from IoT inside the Incident Window",

    "recovered": "The Disconnected vs Recovered Devices count. This bubble displays data on devices that were " +
        "Disconnected in the incident window but connected back after it. This recovered count is " +
        "displayed against the devices that were still disconnected until now",
    "apim": "Donut chart presenting distribution of Devices that made APIM Contact vs Not Contacted after Device got disconnected from IoT Hub",

    "deviceRecoveryTimeBinChart": "The Stacked Bar chart presents Number of Affected, Recovered and Not Recovered devices in" + 
        " each discrete Time duration measuring recovery performance. The Recovery Duration here is relative from the Time device went disconnected",

    "deviceRecoveryTimeLine": "The Scatter Chart presents Devices plotted for Recovery Time vs Recovery Duration." +
        "To zoom in to the chart use Mouse scroll in/out (or) Mouse drag to create rectangular area of selection on Chart."+ 
        "Click on FILTER button to apply cross filter to the Report for visibile devices in chart",

    "placeholdr": "Chart Coming Soon!",

    "tbl": "This table displays all the necessary info on the devices. Some useful features include single/multiple sorting by a " +
        "field (ascending or descending), filtering by single/multiple column fields (for Mulitple sorting, press Ctrl + X to include " +
        "more than one column in sorting), and hiding columns." +
        "To download the data from the table CSV/Excel format, click the download button on the Table top right corner." +
        "More information on the table columns: (1) Device ID: A unique alphanumeric ID assigned to each crestron device." +
        "(2) Incident Status Time: The timestamp for status of a given device in between start and end time of the incident window." +
        "(3) Incident Status: The status of a given device in between start and end time of the incident window." +
        "(4) Last Updated Time: The timestamp for status of a given device in between end time and the current time outside the incident window." +
        "(5) Last Updated Status: The status of a given device in between end time and the current time outside the incident window." +
        "(6) Last Duration: The duration for which a given device was offline or online." +
        "(7) Last Flip Duration: The duration for which a device was online/offline before it switched its status."
}
