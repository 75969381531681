import React, { useRef, useState, useContext } from "react";
import { Doughnut, getElementAtEvent, Bar, Pie, Scatter, Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement,
  Title, Tooltip as ChartToolTip, Legend, Filler, ArcElement, BarElement, TimeScale
} from 'chart.js';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
// import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from 'chartjs-plugin-zoom'
import "chartjs-adapter-date-fns";

import moment from "moment-timezone";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { MakeInfo } from './make_comps';

import { recoveryTimeBinFrequency, infoObj } from './ui_constants.js';

import { FilterIndicator } from "./make_comps";
import { FitScreenOutlined } from "@mui/icons-material";

import { IconButton} from "@mui/material";
import 'chart.js/auto'

import { MasterContext } from "./make_comps";

// import { renderToStaticMarkup } from "react-dom/server"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  ChartToolTip,
  Legend,
  Filler,
  ArcElement,
  BarElement,
  ChartDataLabels,
  zoomPlugin
);


export function DonutC(props) {
  
  const [alrtOpen, setAlrtOpen] = useState(false);
  const chartRef = useRef();
  const filterName = props.filterName;
  const masterContext = useContext(MasterContext);

  // Replot does not auto remove Chart hidden props.
  // useEffect(() => {
  //   console.log(`user Effect called ${masterContext.isReportFiltered}`)
  //   if (!masterContext.isReportFiltered){
  //     chartRef.current.show(0); // hides dataset at index 1
  //     chartRef.current.update(); // chart now renders with dataset hidden
  //   }
  // }, [masterContext.isReportFiltered]);

  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: "right",
        maxWidth: 105,
        maxHeight: 95,
        labels: {
          color: masterContext.buttonStatus ? 'white' : 'black',
          boxWidth:10,
          fontSize: window.innerWidth > 35 ? 20 : 10
        },
        // Disabling Exclude cross filter 
        // onClick:  (evt, legendItem, legend) => {
        //   let dataVisiblity = legend.chart.getDataVisibility(legendItem.index);
        //   legend.chart.toggleDataVisibility(legendItem.index);
        //   legend.chart.update();
        //   //console.log(JSON.stringify(legendItem));
        //   //masterContext.setMiniLoaded(true);
        //   masterContext.UpdateExcludeCrossFilter
        //     ({value : {
        //         [Object.values(filterName).toString()]: {
        //             'value': legendItem.text.trim(),
        //             'operator': "==="
        //           }
        //         },
        //     addFilter: dataVisiblity});
        // }
      }
    }
  };

  const data = {
    labels: props.labls,
    datasets: [{
      cutout: 50,
      data: props.arr,
      backgroundColor: props.colArr,
    }],
  };
  
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlrtOpen(false);
  };

  const handleClick = (event) => {
    const se = getElementAtEvent(chartRef.current, event);
    if (se.length !== 0 && props.isRecov) {
      masterContext.setMiniLoaded(true);
      const labelIdx = se[0].index;
      const getStatus = data.labels[labelIdx];
      if(getStatus === '') {
        masterContext.setFilter(' ');
      } else {
        masterContext.setFilter(getStatus.trim());
      }
      masterContext.setFilterName(Object.values(filterName).toString());
      masterContext.UpdateIncludeCrossFilter
      ({value :  {
          [Object.values(filterName).toString()]: {
            'value': getStatus.trim(),
            'operator': "==="
          }
        },
      });
      // Need to handle it as part of new table implementation
      setAlrtOpen(true);
    }
    else { masterContext.setFilter(''); }

  }

  const alertMssg = "Cross Filtered with \"" + masterContext.getFilter + "\" Status for \"" + Object.keys(filterName) + "\"";

  return (
    <div className="w-56 -mt-9 ml-5">
      <Doughnut data={data} options={options} ref={chartRef}
        onClick={handleClick} />

      <Snackbar open={alrtOpen} autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {alertMssg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export function ApimTimeBinChart(props){
  const masterContext = useContext(MasterContext);
  const textColor = masterContext.buttonStatus ? 'white' : 'black';
  const [alrtOpen, setAlrtOpen] = useState(false);
  const chartRef = useRef();
  const labels = Object.keys(props.incidentTimetoApim);
  const incidentDisconnectToApimFilterId = Object.values(props.incidentDisconnectToApimFilterName)[0];
  const additionalClassProp = props.additionalClassProp === undefined ? {} : props.additionalClassProp;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: textColor,
          align: 'center',
          font: {
            size: window.innerWidth < 1600 ? 10: 12,
          }
        }
      },
      y: {
        // type: 'logarithmic',
        stacked: true,
        ticks: {
          color: textColor,
          align: 'left',
          fontSize: 100
        },
        title: {
          display: true,
          text: 'Device Count',
          color : textColor
        },
      }
    },
    plugins: {
      datalabels: {
       
        formatter: (value, context) => {
          if (value === 0){
            return value = '';
          }
          else {
            return value;
        }
        },
        labels: {
          title: {
            font: {
              weight: "semi-bold",
            }
          },
          value: {
            color: textColor,
          }
        },
    },
    legend: {
      display: true,
      position: 'bottom',
      maxWidth: 200,
      maxHeight: 100,
      align: "left",
      
      labels: {
        color: textColor,
        boxWidth: 10,
        boxHeight: 10,
        font: {
          weight: 'semi-bold',
          size: 3
        },
      },
      onClick:  (evt, legendItem, legend) => {
        // let dataVisiblity = legend.chart.getDataVisibility(legendItem.datasetIndex);
        let dataVisiblity = legend.chart.isDatasetVisible(legendItem.datasetIndex)
        // legend.chart.toggleDataVisibility(legendItem.index);
        // legend.chart.update();
        // // legend.chart.hide(legendItem.index)
        legend.chart.setDatasetVisibility(legendItem.datasetIndex, !legend.chart.isDatasetVisible(legendItem.datasetIndex));
        // props.setMiniLoaded(true);
        masterContext.UpdateExcludeCrossFilter
          ({value : {
    
              [incidentDisconnectToApimFilterId]: {
                  'value': "NA",
                  'operator': "!=="
                }
              },
          addFilter: dataVisiblity});
        
      }
    },
      zoom: {
        zoom: {
          mode: 'x',
          // GT: Adding two options for Zoom control
          drag:  {
            enabled: true,
            backgroundColor: 'rgba(23, 215, 103, 0.09)',
          },
          wheel: {
            enabled: true 
          },
        },
        pan: {
          modifierKey: 'ctrl',
          enabled: true,
          mode: 'x',

        }
      },
      title: {
        display: true,
        // text: 'Title',
      },
    }
  };
  const data = {
    labels,
    datasets: [
      {
        label: 'Device Disconnect to APIM Time (min)',
        type: 'bar',
        // barPercentage: 1.25,
        data: Object.values(props.incidentTimetoApim),
        borderColor: 'rgba(0, 255, 0, 0.4)',
        backgroundColor: 'rgba(0, 255, 0, 0.4)'

      }
    ],
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlrtOpen(false);
  };

  const handleClick = (event) => {
    const se = getElementAtEvent(chartRef.current, event);
    if (se.length !== 0 ) {
      masterContext.setMiniLoaded(true);
      const labelIdx = se[0].index;
      const getStatus = data.labels[labelIdx];
      
      masterContext.UpdateIncludeCrossFilter
      ({value :  {
        [incidentDisconnectToApimFilterId]:{
            'value': getStatus,
            'operator': "==="
      }
    }});
      // Need to add filter clear icon
      setAlrtOpen(true);
      }

    }    

  const alertMssg = "Cross Filtered with \"" + Object.keys(props.incidentDisconnectToApimFilterName)[0]+ "\"";
  return (
    <>
    <h1 className="text-center -mt-2">Device Disconnect to APIM Time Bin</h1>
    <div className='absolute right-8 top-px font-normal'>
      <FilterIndicator 
          setCrossFilter={masterContext.setCrossFilter}
          crossFilter={masterContext.crossFilter} 
          filterName={{...props.incidentDisconnectToApimFilterName}}
          setMiniLoaded={masterContext.setMiniLoaded} />
      <MakeInfo infBody={infoObj.apimContactTimeBin} custClass="inline"/>
    </div>
    
    <div className={`w-11/12 h-5/6 -mt-2 ml-2 ${additionalClassProp}`}>
      <Chart options={options} data={data} ref={chartRef}
        onClick={handleClick} />
      <Snackbar open={alrtOpen} autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {alertMssg}
        </Alert>
      </Snackbar>
    </div>
    </>
  );
}

/**
 * Function to Plot Bar chart representing Device Recovery Time Bin
 * @param {Object} props 
 * @returns JSX of the chart & it's options
 */

export function RecoveryTimeBinChart(props){
  const masterContext = useContext(MasterContext);
  const textColor = masterContext.buttonStatus ? 'white' : 'black';
  const [alrtOpen, setAlrtOpen] = useState(false);
  const chartRef = useRef();
  const labels = Object.keys(props.affectedDeviceTimeStatus);

  const recoveryFilterId = Object.values(props.recoveryFilterName)[0];
  const recoveryTimeBinFilterId = Object.values(props.recoveryTimeBinFilterName)[0]
  const additionalClassProp = props.additionalClassProp === undefined ? {} : props.additionalClassProp;


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: textColor,
          align: 'center'
        },

        title: {
          display: true,
          text: 'Recovery Duration',
          color: textColor,
        },
      },
      y: {
        // type: 'logarithmic',
        stacked: true,
        ticks: {
          color: textColor,
          align: 'left'
        },
        title: {
          display: true,
          text: 'Device Count',
          color : textColor
        },
      }
    },
    plugins: {
      datalabels: {
       
        formatter: (value, context) => {
          if (value === 0){
            return value = '';
          }
          else {
            if (context.datasetIndex === 0) {
              return `${value}\n\n`
            } else {
              return value
            }
        }
        },
        labels: {
          title: {
            font: {
              weight: "semi-bold"
            }
          },
          value: {
            color: textColor,
          }
        },
    },
    legend: {
      position: 'bottom',
      maxWidth: 200,
      maxHeight: 100,
      align: "left",
      
      labels: {
        color: textColor,
        boxWidth: 10,
        boxHeight: 10,
        font: {
          weight: 'semi-bold',
          size: window.innerWidth > 135 ? 3 : 10
        },
      },
    },
      zoom: {
        zoom: {
          mode: 'x',
          // GT: Adding two options for Zoom control
          drag:  {
            enabled: true,
            backgroundColor: 'rgba(23, 215, 103, 0.09)',
          },
          wheel: {
            enabled: true 
          },
        },
        pan: {
          modifierKey: 'ctrl',
          enabled: true,
          mode: 'x',

        }
      },
      title: {
        display: true,
        // text: 'Title',
      },
    }
  };
  const data = {
    labels,
    datasets: [
      {
        label: ' Affected Devices',
        type: 'line',
        tension: .4,
        data: Object.values(props.affectedDeviceTimeStatus),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        // yAxisID: 'y',
        pointRadius: 1.5,
        borderWidth: 1,
        pointHoverRadius: 5,
        borderDash: [2, 5],
        pointHoverBackgroundColor: 'yellow',
        pointBackgroundColor: 'white',
      },
      {
        label: ' Not recovered devices',
        type: 'bar',
        // barPercentage: 1.25,
        data: Object.values(props.notRecoveredDeviceTimeStatus),
        borderColor: 'rgba(255, 99, 132, 0.6)',
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
      {
        label: ' Recovered Devices',
        type: 'bar',
        // barPercentage: 1.25,
        data: Object.values(props.recoveredDeviceTimeStatus),
        borderColor: 'rgba(0, 255, 0, 0.4)',
        backgroundColor: 'rgba(0, 255, 0, 0.4)'
      },

    ],
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlrtOpen(false);
  };

  const handleClick = (event) => {
    const se = getElementAtEvent(chartRef.current, event);
    if (se.length !== 0 && (se[0].datasetIndex === 2 || se[0].datasetIndex === 1)) {
      masterContext.setMiniLoaded(true);
      const labelIdx = se[0].index;
      const getStatus = data.labels[labelIdx];
      let recoveryTimeBinFilter = {};
      let baseFilter = {}
      if (se[0].datasetIndex === 2 ){
        // This Dataset is selection of Recovered Devices
        // Filtering by Status Recovered
        baseFilter = {
          [recoveryFilterId]:{
            'value': "Recovered",
            'operator': "==="
            }};
        // Filtering Devices recovered in the Particular Time Window.
        recoveryTimeBinFilter = {
          [recoveryTimeBinFilterId]: {
            'value': getStatus,
              'operator': '==='
            }}
      }
      else {
        // This Dataset is selection of Not-Recovered Devices on selected Time Window
        // They could have recovered in future Time Windows or did not recover at all ("NA")
        baseFilter = {};
        recoveryTimeBinFilter = {
          [recoveryTimeBinFilterId]: {
            // Filtering All devices that recovered during selected Time Window & After it
            'value': [...recoveryTimeBinFrequency.slice
                          (recoveryTimeBinFrequency.indexOf(getStatus) + 1, recoveryTimeBinFrequency.length),
                    // Filtering devices that are not recovered at all.
                      ...["NA"]],
            'operator': "in",
          }
        }
      }
     masterContext.UpdateIncludeCrossFilter
     ({value :  { ...baseFilter, ...recoveryTimeBinFilter}
      });
     // Need to add filter clear icon
     setAlrtOpen(true);
    }    
  }
  const alertMssg = "Cross Filtered with \"" + Object.values(props.recoveryTimeBinFilterName)[0] 
          + "\" Status for \"" + Object.keys(props.recoveryTimeBinFilterName)[0] + "\"";
  return (
    <>
    <h1 className="text-center -mt-2">Device Recovery Time Bin</h1>
    <div className='absolute right-8 top-px font-normal'>
      <FilterIndicator 
          filterName={{...props.recoveryTimeBinFilterName,
                       ...props.recoveryFilterName}}/>
      <MakeInfo infBody={infoObj.deviceRecoveryTimeBinChart} custClass="inline"/>
    </div>
    
    <div className={`w-11/12 h-5/6 -mt-2 ml-2 ${additionalClassProp}`}>
      <Chart options={options} data={data} ref={chartRef}
        onClick={handleClick} />
      <Snackbar open={alrtOpen} autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {alertMssg}
        </Alert>
      </Snackbar>
    </div>
    </>
  );
}

/**
 * Function to fetch Visible Points in the Chart
 * @param {ChartRef} Chart Reference 
 * @returns {Array} Data points that are visible on the chart
 */

function getVisiblePointsByTimeStamp(c) {
  // Finding Min, Max scales of X & Y axes
  const startX = c.scales["x"]._range["min"] 
  const endX = c.scales["x"]._range["max"] 
  const startY = c.scales["y"]._range["min"]
  const endY = c.scales["y"]._range["max"]
  let values = c.config.data.datasets[0].data;
  return  values.filter((val) => {
    let timestamp = (new Date(val['x'])).getTime();
    let recoveryTime = val['y'];
    return timestamp >= startX && timestamp <= endX && recoveryTime >= startY && recoveryTime <= endY;
  });
}

/**
 * Function to Plot Scatter Time chart of Device Recovery Duration Time vs Recovery Time
 * @param {Object} props 
 * @returns JSX of the chart & it's options
 */

export function ScatterTimeChart(props){
  const masterContext = useContext(MasterContext);
  const textColor = masterContext.buttonStatus ? 'white' : 'black';
  const recoveryTimeFilterName = props.recoveryTimeFilterName;
  const recoveryDurationFilterName = props.recoveryDurationFilterName;
  // const filterEnable = props.filterEnable;
  const chartRef = useRef();
  const additionalClassProp = props.additionalClassProp === undefined ? {} : props.additionalClassProp;


  const handleZoom = () => {

    const visiblePoints = getVisiblePointsByTimeStamp(chartRef.current);
    if (visiblePoints.length === 0){
      return;
    }
    masterContext.setMiniLoaded(true);
    let recoveryTimeArray = visiblePoints.map(point => new Date(point.x).getTime());
    let recoveryDurationArray = visiblePoints.map(point => point.y)
    // console.log(recoveryDurationArray);
    
    // Filtering by Recovery Time & Recovery Duration
    masterContext.UpdateIncludeCrossFilter
    ({value :  {
       [Object.values(recoveryTimeFilterName).toString()]: {
           'value': recoveryTimeArray,
            'operator': 'in'
           },
        [Object.values(recoveryDurationFilterName).toString()]: {
        'value': recoveryDurationArray,
          'operator': 'in'
        } 
         }
     });


}
  // GT: Handle Full view need some more work.
  // const handleFullView = () => {
  //   // console.log(chartRef.current);
  //   chartRef.current.scales['x'].max = props.xMaxZoomTime.getTime();
  //   // console.log(chartRef.current.scales['x'].max);
  //   chartRef.current.update();
  // }

  /**
   * Function to Reset the Zoom of Chart
   */
  const handleZoomReset = ()=>{
    chartRef.current.scales['x'].max = props.xMaxTime;
    chartRef.current.resetZoom();
    chartRef.current.update();
  }



  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        // min : 0,
        display: true,
        ticks: {
            display: true,
            color : textColor,
            callback: function(value, index, values) {
              let formattedValue =  parseFloat(values[index]['value']).toFixed(2).toString();
              if (parseInt(formattedValue.split(".")[1]) === 0){
                  formattedValue = parseInt(formattedValue).toString();
              }
              formattedValue += " min";
              return formattedValue;
        }},
        title: {
          display: true,
          text: 'Recovery Duration (min)',
          color : textColor
        }
    },
      x :{
        type: 'time',
        // by Default fixing TimeLine to Incident Window
        // min: props.xMinTime,
        // max: props.xMaxTime,
        // time: {
        //   parser: function (utcMoment) {
        //       return utcMoment;
        //   }     
        // },
        display: true,
        ticks: {
          minRotation: 15,
          color: textColor,
          font : {
            size: window.innerWidth < 1600? 10: 12,
          },
          callback: function(value, index, values) {
            if (!values[index]) {return}
            return moment.utc(values[index]['value']).format("MMM D, HH:mm:ss");
        },
        },
        title: {
          display: true,
          text: 'Recovery Time (UTC)',
          color : textColor
        }
      }
    },
    responsive: true,
    plugins: {
      tooltip: {
        callbacks:{
          label: function(context) {
            return "Recovery Duration : " + parseFloat(context.raw['y']).toFixed(2);
          },
          title: function(context) {
            return context[0].raw['x'];
          },
        }
      },
      datalabels: {
          display: false,
      },
      legend: {
        display: false,
        position: 'bottom',
        color: textColor,
        labels: {
          color: textColor,
          boxWidth: 10,
          boxHeight: 10,
          font: {
            weight: 'semi-bold',
            size: window.innerWidth > 135 ? 3 : 10
          },
        }
      },
      zoom: {
        limits :{
          // y: {min: 0},
          // y: {min: 0, max: props.yMaxZoomDuration + 10},
          // x: {min: props.xMinTime, max: props.xMaxZoomTime}
        },
        zoom: {
          mode: 'xy',
          
          drag:  {
            enabled: true,
            backgroundColor: 'rgba(23, 215, 103, 0.09)',
          },
          wheel: {
            enabled: true // SET SCROOL ZOOM TO TRUE
          },
          // GT : Not using this, as it will reduce Performance of UI
          // onZoomComplete: handleZoom,

        },
        pan: {
          modifierKey: 'ctrl',
          enabled: true,
          mode: 'xy',

        }
      },

      title: {
        display: true,
        // text: 'Chart.js Line Chart - Multi Axis',
      },
    }
  };
  const data = {
    datasets: [
      {
        label: 'Device Time to Recovery',
        data: props.data,
        // borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.6)'
      }
    ],
  };


  return (
    <>
      <h1 className="text-center -mt-2">Device Recovery Timeline</h1>
      <div className='absolute left-20 top-2 font-normal'>
        <Button className={`hover:text-${textColor} text-${textColor} right-full font-bold` }
         size="small" variant="outlined" onClick={handleZoom}>Filter</Button>
      </div>
      <div className='absolute right-8 top-px font-normal'>
        
        <FilterIndicator  
            filterName={{...recoveryTimeFilterName, ...recoveryDurationFilterName}}/> 
        <Tooltip title="Reset View" arrow placement="top-end">
          <IconButton onClick={handleZoomReset} className={`hover:text-${textColor} text-${textColor}`}> 
              <FitScreenOutlined />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Full View" arrow placement="top-end">
          <IconButton onClick={handleFullView} className={`hover:text-${textColor} text-${textColor}`}> 
              <AspectRatioOutlined />
          </IconButton>
        </Tooltip> */}
        <MakeInfo infBody={infoObj.deviceRecoveryTimeLine} custClass="inline"/>
      </div>
      <div className={`w-11/12 h-5/6 -mt-2 ml-2 ${additionalClassProp}`}>
        <Scatter options={options} data={data} ref={chartRef}/>
      </div>
    </>
  );
}


export function HorizontalBarChart(props) {
  const masterContext = useContext(MasterContext);
  const textColor = masterContext.buttonStatus ? 'white' : 'black'
  const [alrtOpen, setAlrtOpen] = useState(false);
  const chartRef = useRef();
  const filterName = props.filterName;
  
  const additionalClassProp = props.additionalClassProp === undefined ? {} : props.additionalClassProp;
  const options = {
    // animation: {
    //   onComplete: function () {
    //     console.log(chartRef.current.toBase64Image());
    //     document.getElementById('MainLayout').innerHTML  = renderToStaticMarkup(<img src={chartRef.current.toBase64Image()}></img>);
    //     document.getElementById('MainLayout').innerHTML = document.getElementById('Row1').innerHTML;
    //   }},
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 0
    },
    plugins: {
      legend: {
        position: 'bottom',
        maxWidth: 200,
        maxHeight: 100,
        align: "center",
        
        labels: {
          color: textColor,
          boxWidth: 10,
          boxHeight: 10,
          font: {
            weight: 'strong',
            size: window.innerWidth > 135 ? 3 : 10
          },
        },
      },
      datalabels: {
        display: true,
        anchor:'start',
        align: 'end',
        padding: {left: -10},
        labels: {
          title: {
            font: {
              weight: "bold"
            }
          },
          value: {
            color: textColor,
          }
        },
        formatter: function (value) {
          return  value;
        }
      },
    },
    scales: {
      x: {
        position: 'top',
        border: {
          display: false
        },
        grid: {
          display: false, // hides lines in background
        },
        ticks : {display:false},
      },
      y: {
        beginAtZero: false,
        border: {
          display: false
        },
        grid: {
          display: false, // hides lines in background
        },
        ticks: {
          display: true,
          autoSkip: false,
          stepSize: 1,
          font: { weight: 'strong'},
          color: masterContext.buttonStatus ? 'white' : 'black',
        },
      },
    },
  };

  const data = {
    labels: props.labls,
    datasets: [{
      label: props.tooltipName,
      data: Object.values(props.arr),
      backgroundColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(255, 165, 0, 0.4)',
        'rgba(255, 255, 0, 0.4)',
        'rgba(0, 128, 0, 0.4)',
        'rgba(0, 0, 255, 0.4)',
        'rgba(75, 0, 130, 0.4)',
        'rgba(238, 130, 238, 0.4)',
        'rgba(0, 255, 0, 0.4)',
        'rgba(128, 0, 128, 0.4)',
        'rgba(165, 42, 42, 0.4)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(255, 165, 0, 0.4)',
        'rgba(255, 255, 0, 0.4)',
        'rgba(0, 128, 0, 0.4)',
        'rgba(0, 0, 255, 0.4)',
        'rgba(75, 0, 130, 0.4)',
        'rgba(238, 130, 238, 0.4)',
        'rgba(0, 255, 0, 0.4)',
        'rgba(128, 0, 128, 0.4)',
        'rgba(165, 42, 42, 0.4)',
      ],
      borderWidth: 1,
    }],
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlrtOpen(false);
  };

  const handleClick = (event) => {
    const se = getElementAtEvent(chartRef.current, event);
    if (se.length !== 0) {
      masterContext.setMiniLoaded(true);
      const labelIdx = se[0].index;
      const getStatus = data.labels[labelIdx];
      if(getStatus === '') {
        masterContext.setFilter(' ');
      } else {
        masterContext.setFilter(getStatus);
      }
     masterContext.setFilterName(Object.values(filterName).toString());
     masterContext.UpdateIncludeCrossFilter
     ({value :  {
        [Object.values(filterName).toString()]: {
            'value': getStatus,
             'operator': '==='
            }
          }
      });
     // Need to add filter clear icon
     setAlrtOpen(true);
    }
    else { masterContext.setFilter(''); }

    
  }

  const alertMssg = "Cross Filtered with \"" + masterContext.getFilter + "\" Status for \"" + Object.keys(filterName) + "\"";

  return (
    <div className={`w-11/12 h-60 -mt-2 ml-2 ${additionalClassProp}`}>
      <Bar data={data} options={options} ref={chartRef}
        onClick={handleClick} />
      <Snackbar open={alrtOpen} autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {alertMssg}
        </Alert>
      </Snackbar>

    </div>
  );
}

export function DonutKPI(props) {
  const [alrtOpen, setAlrtOpen] = useState(false);
  const chartRef = useRef();
  const filterName = props.filterName;
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        intersect: false,
        mode: 'nearest',
        callbacks: {
          title: (context) => context[0].label,
          label: (context) => context.dataset.label + ": " + context.parsed,
        },
      },
      legend: {
        position: 'left',
        maxWidth: 200,
        maxHeight: 135,
        // onClick: (evt, legendItem, legend) => {
        //   legend.chart.toggleDataVisibility(legendItem.index);
        //         legend.chart.update();
        // },
        onClick:  (evt, legendItem, legend) => {
          //console.log(JSON.stringify(legendItem));
          let dataVisiblity = legend.chart.getDataVisibility(legendItem.index);
          console.log(`Data Visibility ${dataVisiblity}`);
          //legend.chart.toggleDataVisibility(legendItem.index);
          //legend.chart.update();
          //console.log(JSON.stringify(legendItem));
          
          props.UpdateExcludeCrossFilter
            ({value : {
                [Object.values(filterName).toString()]: {
                  'value': legendItem.text.trim(),
                  'operator': "==="
                }
              },
              addFilter: dataVisiblity});
        },
        labels: {
          padding: 5,
          color: props.buttonStatus ? 'white' : 'black',
          boxWidth:10,
          boxHeight: 9,
          font: { weight: 'strong', size: window.innerWidth > 35 ? 9 : 10,},
          // generateLabels: function(chart) {
          //   var data = chart.data;
          //   if (data.labels.length && data.datasets.length) {
          //     return data.labels.map(function(label, i) {
          //       var ds = data.datasets[0];
          //         return {
          //         // And finally : 
          //         text: label,
          //         fillStyle: ds.backgroundColor[i],
          //         strokeStyle: ds.borderColor[i],
          //         lineWidth: ds.borderWidth,
          //         hidden: !chart.getDataVisibility(i),
          //         index: i
          //       };
          //     });
          //   }
          //   return [];
          // }
        }
      },
      datalabels: {
        display: true,
        anchor:'center',
                labels: {
          title: {
            font: {
              weight: "bold"
            }
          },
          value: {
            color: props.buttonStatus ? 'white' : 'black',
          }
        },
        formatter: function (value) {
          return  value;
        },
      }  
    },
  };

  const data = {
    labels: props.labls,
    datasets: [{
      cutout: 48,
      label: props.tooltipName,
      data: Object.values(props.arr),
      backgroundColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(255, 165, 0, 0.4)',
        'rgba(255, 255, 0, 0.4)',
        'rgba(0, 128, 0, 0.4)',
        'rgba(0, 0, 255, 0.4)',
        'rgba(75, 0, 130, 0.4)',
        'rgba(238, 130, 238, 0.4)',
        'rgba(0, 255, 0, 0.4)',
        'rgba(128, 0, 128, 0.4)',
        'rgba(165, 42, 42, 0.4)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(255, 165, 0, 2)',
        'rgba(255, 255, 0, 2)',
        'rgba(0, 128, 0, 2)',
        'rgba(0, 0, 255, 2)',
        'rgba(75, 0, 130, 2)',
        'rgba(238, 130, 238, 2)',
        'rgba(0, 255, 0, 2)',
        'rgba(128, 0, 128, 2)',
        'rgba(165, 42, 42, 2)',
      ],
      borderWidth: 1,
      hoverOffset: 1,
    }],
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlrtOpen(false);
  };

  const handleClick = (event) => {
    const se = getElementAtEvent(chartRef.current, event);
    if (se.length !== 0) {
      props.setMiniLoaded(true);
      const labelIdx = se[0].index;
      const getStatus = data.labels[labelIdx];
      if(getStatus === '') {
        props.setFilter(' ');
      } else {
        props.setFilter(getStatus);
      }
      props.setFilterName(Object.values(filterName).toString());
      props.UpdateIncludeCrossFilter
      ({value : {
          [Object.values(filterName).toString()]: {
            'value': getStatus, 
            'operator': '==='}
          }
        });
      // Need to add filter clear Icon..
      setAlrtOpen(true);
    }
    else { props.setFilter(''); }

    
  }


  const alertMssg = "Cross Filtered with \"" + props.getFilter + "\" Status for \"" + Object.keys(filterName) + "\"";


  return (
    <div className="w-full mt-1 ml-2">
      <Doughnut data={data} options={options}  
      ref={chartRef} onClick={handleClick} />
      <Snackbar open={alrtOpen} autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {alertMssg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export function PieKPI(props) {
  const [alrtOpen, setAlrtOpen] = useState(false);
  const chartRef = useRef();
  const filterName = props.filterName;

  const options = {
    // layout: {
    //   padding: {
    //     bottom: 3,
    //     top: 3
    //   }
    // },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        intersect: false,
        mode: 'nearest',
        callbacks: {
          title: (context) => context[0].label,
          label: (context) => context.dataset.label + ": " + context.parsed,
        },
      },
  
      legend: {
        position: 'left',
        maxWidth: 200,
        maxHeight: 135,
        onClick:  (evt, legendItem, legend) => {
          //console.log(JSON.stringify(legendItem));
          let dataVisiblity = legend.chart.getDataVisibility(legendItem.index);
          console.log(`Data Visibility ${dataVisiblity}`);
          //legend.chart.toggleDataVisibility(legendItem.index);
          //legend.chart.update();
          //console.log(JSON.stringify(legendItem));
          
          props.UpdateExcludeCrossFilter
            ({value : {
                [Object.values(filterName).toString()]: {
                  'value': legendItem.text.trim(),
                  'operator': "==="
                }
              },
              addFilter: dataVisiblity});
        },
        labels: {
          color: props.buttonStatus ? 'white' : 'black',
          boxWidth:10,
          boxHeight: 3.5,
          fontSize: window.innerWidth > 135 ? 20 : 10,
          font: { weight: 'strong', size: window.innerWidth > 135 ? 3.5 : 10},
        }
      },
      title: {
        display: false,
        text: "Avg interest by month (days)",
        padding: {
          bottom: 10
        },
        weight: "bold",
        color: "#00325c",
        font: {
          size: 13
        },
        align: "start"
      },
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        padding: {
          left: 10
        },
        labels: {
          padding: { top: 0 },
          title: {
            font: {
              weight: "bold"
            }
          },
          value: {
            color: "black"
          }
        },
        formatter: function (value) {
          return  value;
        }
      },
  
    },
  };
  
  const data = {
    labels: props.labls,
    datasets: [{
      label: props.tooltipName,
      data: Object.values(props.arr),
      backgroundColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(255, 165, 0, 0.4)',
        'rgba(255, 255, 0, 0.4)',
        'rgba(0, 128, 0, 0.4)',
        'rgba(0, 0, 255, 0.4)',
        'rgba(75, 0, 130, 0.4)',
        'rgba(238, 130, 238, 0.4)',
        'rgba(0, 255, 0, 0.4)',
        'rgba(128, 0, 128, 0.4)',
        'rgba(165, 42, 42, 0.4)',
      ],
      borderColor: [
        'rgba(255, 0, 0, 2)',
        'rgba(255, 165, 0, 2)',
        'rgba(255, 255, 0, 2)',
        'rgba(0, 128, 0, 2)',
        'rgba(0, 0, 255, 2)',
        'rgba(75, 0, 130, 2)',
        'rgba(238, 130, 238, 2)',
        'rgba(0, 255, 0, 2)',
        'rgba(128, 0, 128, 2)',
        'rgba(165, 42, 42, 2)',
      ],
      borderWidth: 1,
      hoverOffset: 8,
    }],
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlrtOpen(false);
  };
  
  const handleClick = (event) => {
    const se = getElementAtEvent(chartRef.current, event);
    if (se.length !== 0) {
      props.setMiniLoaded(true);
      const labelIdx = se[0].index;
      const getStatus = data.labels[labelIdx];
      if(getStatus === '') {
        props.setFilter(' ');
      } else {
        props.setFilter(getStatus);
      }
      props.setFilterName(Object.values(filterName).toString());
      props.UpdateIncludeCrossFilter
      ({value : {
          [Object.values(filterName).toString()]: {
            'value' :getStatus, 'operator' : "==="}
          },
        }
      );
    }
    else { props.setFilter(''); }

    setAlrtOpen(true);
  }


  const alertMssg = "Cross Filtered with \"" + props.getFilter + "\" Status for \"" + Object.keys(filterName) + "\"";

  return (
    <div className="w-full mt-1 ml-3">
      <Pie data={data} options={options}  
      ref={chartRef} onClick={handleClick} />
      <Snackbar open={alrtOpen} autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {alertMssg}
        </Alert>
      </Snackbar>
    </div>
  );
}