import { getTimeWindow } from "./parse_data";



/**
 * Function to remove Cross filter from both include & exclude constraint
 * @param {Object} crossFilter 
 * Ex : {"exclude":{"ConnectedBackToApim":"No"},"include":{"RecoveryStatus":"Recovered","Model":"UC-ENGINE"}}
 * @param {String/Array} filterNameKey 
 * Ex: "RecoveryStatus"
 * @returns {Object} crossFilter object
 */

export const removeCrossFilter = (crossFilter, filterNameKey) => {
  let filterNameKeyArray = filterNameKey instanceof Array ? filterNameKey : [filterNameKey];
  for (const filterType in crossFilter){
    filterNameKeyArray.forEach(filterNameKeyElement => {
        if (filterNameKeyElement in crossFilter[filterType]){
          delete crossFilter[filterType][filterNameKeyElement];
      }
    });
  }
  return crossFilter;
}



export const removeSelectedCrossFilter = (selectedCrossFilter, filterNameKey) => {
  let filterNameKeyArray = filterNameKey instanceof Array ? filterNameKey : [filterNameKey];
  filterNameKeyArray.forEach(filterNameKeyElement => {
      if (filterNameKeyElement in selectedCrossFilter){
        selectedCrossFilter[filterNameKeyElement] = [];
      }
  });
  return selectedCrossFilter;
}



export const getSelectedCrossFilter = (crossFilter, multiSelectFilterInit) => {
  let selectedCrossFilter = {...multiSelectFilterInit};
  for (const filterItem in crossFilter["include"]){
      if (!selectedCrossFilter.hasOwnProperty(filterItem)){
        continue;
      }
      let filterValue = crossFilter["include"][filterItem]["value"];
      filterValue = filterValue instanceof Array? filterValue : [filterValue];
      let filterValueObjList = [];
      // Handling Select Button Components
      if (filterItem === 'IsAncillary'){
        filterValueObjList = filterValue;
      }
      // Handling Multi-Select components
      else {
        filterValue.forEach(item => {
          filterValueObjList.push({[item]: "NA", "name": item})
        });
      }
      selectedCrossFilter[filterItem] = filterValueObjList;
  }
  return selectedCrossFilter;
}


/**
 * 
 * @param {Object} filter - Include/Exclude filter. 
 * Ex : {"Model":"UC-ENGINE","RecoveryStatus":"Recovered","AccountName":"CIBC "}
 * @param {Object} filterValue - Filter Object. 
 * Ex : {"RecoveryStatus":"Recovered"}
 * @returns {Object} Include/Exclude filter (with filterValue key removed)
 */

export const removeFilter = (filter, filterValue) => {
    let filterKeys = Object.keys(filterValue);
    [...filterKeys].forEach( filterKey => {
      if (filterKey in filter){

        delete filter[filterKey];
      }
    });
    return filter;
}

/**
 * Function to check if CrossFilter is applied to Report
 * @param {Object} crossFilter 
 * Ex : {"exclude":{"ConnectedBackToApim":"No"},"include":{"RecoveryStatus":"Recovered","Model":"UC-ENG
 * @returns {boolean} true/false
 */

export const isReportCrossFiltered = (crossFilter) => {
    return !(Object.keys(crossFilter.exclude).length < 1 && Object.keys(crossFilter.include).length < 1);
}

export const isFilterApplied = (crossFilter, filterNameKey) => {
    let isFilterSet = false;
    for (const filterType in crossFilter){
        if (filterNameKey in crossFilter[filterType]){
            isFilterSet = true;
            break;
        }
      }
    return isFilterSet;
}

/**
 * Function to Get Filter value of Cross Filter
 * @param {Object} crossFilter Cross Filter Object
 * @param {String} filterNameKey Name of the Filter
 * @returns {String} Value of the Filter
 */

export const getFilterValue = (crossFilter, filterNameKey)=> {
  if (isFilterApplied(crossFilter, filterNameKey)){
    for (const filterType in crossFilter){
      if (filterNameKey in crossFilter[filterType]){
          return crossFilter[filterType][filterNameKey]['value'];
      }
    }
}}

/**
 * Filter Operation definitions
 */
const filterOperators = {
  '===': function(a, b){ return a === b},
  '!==': function(a, b){ return a !== b},
  '>': function(a, b){ return a > b},
  '>=': function(a, b){ return a >= b},
  // Converting RecoveryDuration in Seconds to Minutes before finding Time Window
  'matchTimeWindow': function(a, b){return getTimeWindow(a) === b;},
  'in': function(a,b){return b.includes(a);}
}


/**
 * Function to check if criteria is matched for the Filter operation.
 * @param {any} value 
 * @param {Object} filterElement 
 * @returns {boolean} Criteria match result
 */

const checkFilterCriteria = (value, filterElement) => {
  return filterOperators[filterElement['operator']](value, filterElement['value']);
}

export const filterOutTableRows = (tableRows, crossFilter) => {
        // Filtering based on Original item always
        let includeFilters = crossFilter['include'];
        let excludeFilters = crossFilter['exclude'];
        let exclude_filter_keys = Object.keys(crossFilter['exclude']);
        let include_filter_keys = Object.keys(crossFilter['include']);
        // Computing list of data that needs to be removed after Filtering.
        var dataArrayToRemove = tableRows.filter(function (deviceInfo)
        {
          let filterOutFlag = true;
          // Apply Exclude filter
          [...exclude_filter_keys].every( element => {
            
            // filterOutFlag = (filterOutFlag) && (deviceInfo[element] !== excludeFilters[element]);
            filterOutFlag = (filterOutFlag) && (!(checkFilterCriteria(deviceInfo[element], excludeFilters[element])));
            //console.log(`${element} ${deviceInfo[element]} ${excludeCrossFilter[element]} ${filter_out}`);
            return true;
          })
          // Apply include filter
          if (filterOutFlag){
            [...include_filter_keys].every( element => {
              
              // filterOutFlag = (filterOutFlag) && (deviceInfo[element] === includeFilters[element]);
              filterOutFlag = (filterOutFlag) && (checkFilterCriteria(deviceInfo[element], includeFilters[element]));
              //console.log(`${element} ${deviceInfo[element]} ${includeFilters[element]} ${filter_out}`);
              return true;
            })
          }
          //console.log(filter_out);
          if (!filterOutFlag){
            //console.log(`Excluded Device ${deviceInfo.id}`);
          }
          return !filterOutFlag;
        }
        );
        //console.log(`Filtered Devices...${dataArrayToRemove.length}`);
        return dataArrayToRemove;
}

/**
 * Function to remove Data that are filtered out.
 * @param {Array} dataArrayToRemove 
 * @param {Array} items 
 * @returns {Array} Filtered Items
 */

export const filterDataItems = (dataArrayToRemove, items) => {
    let filteredIndexes = [];
    let filteredItems = [];
    // calculating data index to remove from items
    for (let i=0; i < dataArrayToRemove.length; i++){
      // As first index will have START null value element
      filteredIndexes.push(dataArrayToRemove[i]['id']);
    }
    // Filtering Items by excluding filteredIndexes
    for (let i=0; i< items.length; i++){
      if (!filteredIndexes.includes(i)){
        filteredItems.push(items[i]);
      }
    }
    return filteredItems;
}


export const setDropDownCrossFilter = (compoundFilterObj, filterName, filterValue, filterOperation="in") => {
  let filterValueList = [];
  if (filterValue !== null){
    filterValue.forEach(filterItem => {
      filterValueList.push(filterItem['name'] === undefined ? filterItem : filterItem['name']);
    });
  }
  const filterAttribute = {[filterName]: {
    'value': filterValueList,
    'operator': filterOperation,
  }}
  if(filterValueList.length > 0){
    compoundFilterObj['add'] = {...compoundFilterObj['add'], ...filterAttribute};
  }
  else {
    compoundFilterObj['remove'] = {...compoundFilterObj['remove'], ...filterAttribute};
  }
}