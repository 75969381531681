import React, { useState, useEffect, useRef } from 'react';
import { parseDonutData, ParseData } from './incidentReportPages/parse_iot_data';
import { generateURLParams } from './incidentReportPages/parse_data';
import RenLayout from './incidentReportPages/make_iot_layout';
import { Loadin, MiniLoadin } from './incidentReportPages/loading_anim';
import configData from './incidentReportPages/config.json';
import './incidentReportPages/index.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { filterDataItems, filterOutTableRows, getFilterValue, getSelectedCrossFilter, isFilterApplied, isReportCrossFiltered, removeFilter } from './incidentReportPages/cross_filter';
import { MasterContext, GenerateRequestID } from './incidentReportPages/make_comps';
import { useMsal } from '@azure/msal-react';
import { REACT_APP_AZURE_AD_FUNCAPP_CLIENTID } from '../authConfig';

export function GetIoTDeviceData() {
  const urlparams = generateURLParams();

  const start = urlparams.start;
  const end = urlparams.end;
  const aot = urlparams.aot;
  const {instance} = useMsal();
  const urlPath = `?from_ts=${urlparams.fromTs}&&to_ts=${urlparams.toTs}`;
  window.history.replaceState(null, null, urlPath);

  const base = configData.SERVER_URL;
  // TODO: Randomize Request Id
  const params = "?RequestId=" + GenerateRequestID("IoT") + "&StartTime=%27" + start +
    "%27&EndTime=%27" + end + "%27&AsOfTime=%27" + aot + "%27&ReportType=IoT";

  const genurl = base + params;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [miniLoaded, setMiniLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [geturl, setUrl] = useState(genurl);
  const [alrtOpen, setAlrtOpen] = useState(false);
  const [crossFilter, setCrossFilter] = useState({'include':{}, 'exclude':{}});
  // Init Multi-Select filters 
  const multiSelectFilterInit = {
    'AccountName': [], 'Model': [] ,'DeviceVersion': [],  'ConnectedBackToApim': [],
    'ProbableCause':[], 'RecoveryStatus': [], 'IsAncillary': [], 'RecoveryTimeBin': [],
    'IncidentDisconnectToApimTimeBin': [],  'Location': [], 'IotLocation': []
  };
  // const multiSelectFilterFields = Object.keys(multiSelectFilterInit);
  const [selectedCrossFilter, setSelectedCrossFilter] = useState(multiSelectFilterInit);
  const [crossFilterChange, setCrossFilterChange] = useState(false);
  const crossFilterOverlayPanelToggle = useRef(null);

  const [buttonStatus, setButtonStatus] = useState(localStorage.theme === 'dark' ? true: false);
  // TODO: cleanup these useStates - Not used
  const [getFilter, setFilter] = useState('');
  const [getFilterName, setFilterName] = useState('');

  const tableRows = useRef([]);
  const isReportFiltered = useRef(false);
  const masterObj = useRef({});
  const donutd = useRef({});
  const tempfilteredItems = useRef([]);

  // Function for Updating Exclude cross filter values
  const UpdateExcludeCrossFilter = ({value, addFilter=true, removeValue={}}) => {
    // updating details of Cross Filter.
    let includeFilter = {...crossFilter.include}
    let excludeFilter = {...crossFilter.exclude}
    if (removeValue){
      excludeFilter = {...removeFilter(excludeFilter, removeValue)}
    }
    // Adding exclude filter
    if (addFilter){
      setCrossFilter({'exclude': {...excludeFilter, ...value}, 'include': includeFilter})
    }
    // Removing exclude filter
    else {
      setCrossFilter({'exclude': {...removeFilter(excludeFilter, value)}, 'include': includeFilter})
    }
  }

  // Function for Updating Include cross filter values
  const UpdateIncludeCrossFilter = ({value, addFilter=true, removeValue={}}) => {
    //console.log(`Before Filters ${JSON.stringify(crossFilter)}`);
    // updating details of Cross Filter.
    let includeFilter = {...crossFilter.include}
    let excludeFilter = {...crossFilter.exclude}
    // For cases where some Filters are added & some are removed.
    if (removeValue){
      includeFilter = {...removeFilter(includeFilter, removeValue)}
    }
    // Adding include filter
    if (addFilter){
      setCrossFilter({'exclude': excludeFilter, 'include': {...includeFilter, ...value}});
    }
    // Removing include filter
    else {
      setCrossFilter({'exclude': excludeFilter, 'include':{...removeFilter(includeFilter, value)}})
    }
  }

  const UpdateSelectedCrossFilter = ({
      AccountName=null,
      Model=null,
      DeviceVersion=null,
      RecoveryStatus=null,
      ProbableCause=null,
      ConnectedBackToApim=null,
      IsAncillary=null,
      RecoveryTimeBin=null,
      IncidentDisconnectToApimTimeBin=null,
      Location=null,
      IotLocation=null
    }) => {

    const updatedCrossFilterSelection = {
      'AccountName': AccountName !== null ? AccountName: selectedCrossFilter['AccountName'],
      'Model': Model !== null ? Model : selectedCrossFilter['Model'],
      'DeviceVersion': DeviceVersion !==null  ? DeviceVersion: selectedCrossFilter['DeviceVersion'],
      'RecoveryStatus': RecoveryStatus !==null  ? RecoveryStatus: selectedCrossFilter['RecoveryStatus'],
      'ProbableCause': ProbableCause !==null  ? ProbableCause: selectedCrossFilter['ProbableCause'],
      'ConnectedBackToApim': ConnectedBackToApim !==null  ? ConnectedBackToApim: selectedCrossFilter['ConnectedBackToApim'],
      'IsAncillary': IsAncillary !==null  ? IsAncillary: selectedCrossFilter['IsAncillary'],
      'RecoveryTimeBin': RecoveryTimeBin !==null  ? RecoveryTimeBin: selectedCrossFilter['RecoveryTimeBin'],
      'IncidentDisconnectToApimTimeBin': IncidentDisconnectToApimTimeBin !==null  ? IncidentDisconnectToApimTimeBin: selectedCrossFilter['IncidentDisconnectToApimTimeBin'],
      'Location': Location !==null  ? Location: selectedCrossFilter['Location'],
      'IotLocation': IotLocation !==null  ? IotLocation: selectedCrossFilter['IotLocation']
    }
    // Updating selected cross filters. Note : Still they are not updated
    setSelectedCrossFilter(updatedCrossFilterSelection);
    setCrossFilterChange(true);
  }

  const clearCrossFilterSelection = () => {
    // Clearing all cross filter selection in the Multi-Select Dropdown
    setSelectedCrossFilter({...multiSelectFilterInit})
  }

  useEffect(() => {
    isReportFiltered.current = isReportCrossFiltered(crossFilter);
    if (!isReportFiltered.current){
      setFilteredItems([...items]);
      return;
    }
    console.log(`Current Filters ${JSON.stringify(crossFilter)}`);
    // Updating Multi-Select Filter Items from Cross Filter Values
    // Here, user can either apply cross filter from Multi-Select dropdown on Top of page
    // Or directly interact with chart for single dataset selection.
    // So when  user selects via Charts, below updates items to Multi-select Dropdown
    setSelectedCrossFilter(getSelectedCrossFilter(crossFilter, multiSelectFilterInit));
    // Filtering based on Original item always
    let tempItems = [...items];
    let includeFilters = crossFilter['include'];
    let excludeFilters = crossFilter['exclude'];
    let tempRows = [];
    [masterObj.current,  tempRows] = ParseData(tempItems);

    // Computing list of data that needs to be removed after Filtering.
    var dataArrayToRemove = filterOutTableRows(tempRows, crossFilter);
    tableRows.current = dataArrayToRemove;
    tempfilteredItems.current = filterDataItems(dataArrayToRemove, items);

    // If filtered device is Empty, then clear all filters
    if (tempfilteredItems.current.length < 3){
      let deviceNotFoundMsg = "";
      if (Object.keys(includeFilters).length > 0){
        deviceNotFoundMsg += "Include Filter Criteria =>\n"
        Object.keys(includeFilters).forEach(k => {
          deviceNotFoundMsg += k + ": " + JSON.stringify(includeFilters[k]) + "\n";
        });
      }
      if (Object.keys(excludeFilters).length  > 0){
        deviceNotFoundMsg += "\nExclude Filter Criteria =>\n"
        Object.keys(excludeFilters).forEach(k => {
          deviceNotFoundMsg += k + ": " + JSON.stringify(excludeFilters[k]) + "\n";
        });
      }
      alert(`No device found for Applied filter\n\n${deviceNotFoundMsg}\n`);
      setCrossFilter({'exclude': {}, 'include': {}});
      clearCrossFilterSelection();
      // Visible loading animation is Disabled once Data Filtering is completed
      if (miniLoaded){
        setMiniLoaded(false);
      }
      return;
    }
    setFilteredItems(tempfilteredItems.current);
    // Visible loading animation is Disabled once Data Filtering is completed
    if (miniLoaded){
      setMiniLoaded(false);
    }
    //console.log(tempfilteredItems.current);
    // eslint-disable-next-line
  }, [crossFilter]);

  useEffect(() => {
    let accessToken;
    instance
    .acquireTokenSilent({
      scopes: [`api://${REACT_APP_AZURE_AD_FUNCAPP_CLIENTID}/invoke`],
      account: instance.getAllAccounts()[0],
    })
    .then((accessTokenResponse) => {
      // Acquire token silent success
      accessToken = accessTokenResponse.accessToken;
      console.log(`Current URL: ${geturl}`);
      var headers = new Headers();
      headers.append("Authorization", "Bearer " + accessToken);
      fetch(geturl, {
        method: "GET",
        headers: headers})
      .then(res => res.json())
      .catch(function(){
        console.log("Internal Error 500")
      })
      .then(
        (result) => {
          if (miniLoaded){
            setMiniLoaded(false);
            setAlrtOpen(true);
          }
          else setIsLoaded(true);
          setItems(result);
          setFilteredItems(result);
          [masterObj.current, ] = ParseData(result, false);
          donutd.current = parseDonutData(masterObj.current);
          //clearing all filters on Data reload
          setCrossFilter({'include': {}, 'exclude': {}});
          clearCrossFilterSelection();
        },
        // Note: it's important to handle errors here instead of a catch() block
        // so that we don't swallow exceptions from actual bugs in components.
        (error) => {
          if (miniLoaded){
            setMiniLoaded(false);
            setAlrtOpen(true);
          }
          else setIsLoaded(true);
          setError(error);
          masterObj.current = {};
          donutd.current = {};
          setCrossFilter({'include': {}, 'exclude': {}});
          clearCrossFilterSelection();
        }
      )
    })
      // eslint-disable-next-line
    }, [geturl]);//, setUrl, miniLoaded, setMiniLoaded, isLoaded]);
 

  if (error) { return <div className="text-header-txt-d text-center">Error: {error.message}</div>; }
  else if (!isLoaded) return <Loadin />;
  else {
    let filteredmasterObj = {};
    // table always presents filtered data
    [filteredmasterObj, tableRows.current] = ParseData(filteredItems);
    // const filteredmasterObj = {...filteredmasterObjtemp};
    const filteredDonutd = parseDonutData(filteredmasterObj, tableRows.current);
    
    tempfilteredItems.current = [...filteredItems];
    const isRecoveryFilterApplied = isFilterApplied(crossFilter, "RecoveryStatus") 
    || isFilterApplied(crossFilter, "RecoveryDuration") 
    || isFilterApplied(crossFilter, "RecoveryTime");
    // Checking if Filter Applied and Updating Chart Header
    const appliedRecoveryFilterValue = getFilterValue(crossFilter, "RecoveryStatus");
    const recoveryStatus = appliedRecoveryFilterValue === undefined ? "Affected" : appliedRecoveryFilterValue;


    return (
      <>
        <Grid container justifyContent="center">
          <AuthenticatedTemplate>
            <MasterContext.Provider
             value={
                {
                  miniLoaded: miniLoaded,
                  alrtOpen: alrtOpen,
                  setAlrtOpen: setAlrtOpen,
                  devArr: tempfilteredItems.current,
                  setUrl: setUrl,
                  setMiniLoaded: setMiniLoaded,
                  UpdateExcludeCrossFilter: UpdateExcludeCrossFilter,
                  UpdateIncludeCrossFilter: UpdateIncludeCrossFilter,
                  setCrossFilter: setCrossFilter,
                  crossFilter: crossFilter,
                  isReportFiltered: isReportFiltered.current,
                  masterObj: filteredmasterObj,
                  donutd: filteredDonutd,
                  tableRows: tableRows.current,
                  clearCrossFilterSelection: clearCrossFilterSelection,
                  selectedCrossFilter: selectedCrossFilter,
                  setSelectedCrossFilter: setSelectedCrossFilter,
                  UpdateSelectedCrossFilter: UpdateSelectedCrossFilter,
                  crossFilterChange: crossFilterChange,
                  crossFilterOverlayPanelToggle: crossFilterOverlayPanelToggle,
                  setCrossFilterChange: setCrossFilterChange,
                  isRecoveryFilterApplied : isRecoveryFilterApplied,
                  appliedRecoveryFilterValue: appliedRecoveryFilterValue,
                  recoveryStatus: recoveryStatus,
                  buttonStatus: buttonStatus,
                  setButtonStatus: setButtonStatus,
                  getFilter: getFilter,
                  setFilter: setFilter,
                  getFilterName: getFilterName,
                  setFilterName: setFilterName,
                  // Multi-Select Filter Options
                  multiSelectFilterInit: multiSelectFilterInit
                }
              }>
              <RenLayout/>
              <MiniLoadin />
            </MasterContext.Provider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
              <Typography variant="h6">
                <center>Please sign-in to see incident report.</center>
              </Typography>
            </UnauthenticatedTemplate>
        </Grid>
      </>
    );
  }
}
