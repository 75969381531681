
import React from 'react';

const AccessDeniedPage = (message) => {

    return (
        <div className="surface-ground flex align-items-center justify-content-center min-w-screen overflow-hidden">
            <div className="flex flex-column align-items-center justify-content-center  w-96">
                <div className='mb-5 mt-20 rounded-xl' 
                    style={{
                        padding: '0.5rem',
                        background: 'linear-gradient(0.3turn, #60c7f7, #60edf7, #60edf7, #60c7f7)'
                    }}
                >
                    <div className="w-full surface-card py-8 sm:px-8 flex flex-column align-items-center" style={{ borderRadius: '2px' }}>
                        <div className="flex justify-content-center align-items-center bg-blue-500 rounded-xl border-circle" style={{ height: '3.2rem', width: '3.2rem' }}>
                            <i className="pi pi-fw pi-exclamation-circle text-2xl text-white"></i>
                        </div>
                        <h1 className="text-600 font-bold text-2xl mb-5 mt-2">Access Denied</h1>
                        <div className="text-black mb-5">{message}</div>
                        <div className="text-black mb-2">Permitted Security Groups:<li>EsOps</li><li>CrestronSupportGroup</li></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessDeniedPage;
