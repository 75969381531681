//import * as React from 'react';
import React, { useContext, useState, createContext} from 'react';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { generateURLParams, aotObj } from './parse_data';
import useDarkSide from './dark_mode';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// -------------------- Hosts the Streambase SERVER URL ------------------------
import configData from './config.json';

// -------------------- Time Picker --------------------------------------------
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import UpdateIcon from '@mui/icons-material/Update';
import { isFilterApplied, removeCrossFilter, removeSelectedCrossFilter } from './cross_filter';
import { Tooltip } from '@mui/material';


// A tmp variable to handle Close of Dialog on Clicking "Go" button
let tmpOnClose;


let updatedAot = aotObj.toISOString();
updatedAot = updatedAot.replace("T", " ");
updatedAot = updatedAot.split(".")[0];


let generatedAot = aotObj.toISOString();
generatedAot = updatedAot.replace("T", " ");
generatedAot = updatedAot.split(".")[0];



function getHrsMinsSecsDiff(parsedDevInfo, aotObj) {
  const endTimeObj =  generateURLParams()['endObj']
  var seconds = Math.floor((aotObj - (endTimeObj))/1000);
  var minutes = Math.floor(seconds/60);
  var hours = Math.floor(minutes/60);
  var days = Math.floor(hours/24);

  hours = hours-(days*24);
  minutes = minutes-(days*24*60)-(hours*60);
  seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
  return [hours, minutes, seconds];
}

const Dropdown = ({ label, value, options, onChange }) => {
  return (
    <label id={label} className="text-xs justify-between bg-prob-c-l light:bg-prob-c-d -mt-2 ml-2 pr-4 pl-0.3">
      <b>{label}</b>
      <select className="text-xs justify-between bg-main-txt-d light:bg-main-bg-d -mt-2 ml-0.5 pl-2"
        value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.label} className="text-xs justify-between bg-main-txt-d dark:bg-main-bg-d -mt-2 -ml-1 pl-1" value={option.value}>{option.label}</option>
        ))}
      </select>
    </label>
  );
};

export function TimeWindowSelector(props) {
  const nowObj = props.now;
  const masterObj = props.devArr;

  // methods to handle Dialog for TimePicker
  const { onClose, open } = props;
  tmpOnClose = onClose;
  const handleClose = () => {
    onClose();
  };


  // get the difference in hours, mins, seconds
  const [calculatedHours, calculatedMinutes, calculatedSeconds] = getHrsMinsSecsDiff(masterObj, nowObj);

  const [hour, setHour] = React.useState(calculatedHours);
  const [minutes, setMinutes] = React.useState(calculatedMinutes);
  const [seconds, setSeconds] = React.useState(calculatedSeconds);


  const handleHourChange = (event) => {
    setHour(event.target.value);
  };

  const handleMinutesChange = (event) => {
    setMinutes(event.target.value);
  };

  const handleSecondsChange = (event) => {
    setSeconds(event.target.value);
  };

  // need this to generate a list of range of values for hours, minutes, seconds
  const range = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min);


  // calculate hour range
  var myHourColumnDefs = [];
  const hourRange = range(0,calculatedHours);
  for (let i = 0; i < hourRange.length ; i++) {
    myHourColumnDefs.push({label: -i, value: i});
  }
  const hourOptions = myHourColumnDefs;



  // calculate minutes range
  var myMinutesColumnDefs = [];
  const minutesRange = range(0,calculatedMinutes);
  for (let i = 0; i < minutesRange.length ; i++) {
    myMinutesColumnDefs.push({label: -i, value: i});
  }
  const minutesOptions = myMinutesColumnDefs;


  // calculate seconds range
  var mySecondsColumnDefs = [];
  const secondsRange = range(0,calculatedSeconds);
  for (let i = 0; i < secondsRange.length ; i++) {
    mySecondsColumnDefs.push({label: -i, value: i});
  }
  const secondsOptions = mySecondsColumnDefs;


  // This function will take the new date time and regenerate data from Streambase
  function handleDateTimeChange() {
    const urlparams = generateURLParams();
    let aot = urlparams.aot;
    const base = configData.SERVER_URL;

    // calculate aot
    let tmpAot = new Date(aot.replace("%20","T"));
    tmpAot.setHours(tmpAot.getHours() - hour);
    tmpAot.setMinutes(tmpAot.getMinutes() - minutes);
    tmpAot.setSeconds(tmpAot.getSeconds() - seconds);
    // convert the date back to toISOString
    tmpAot = new Date(new Date(tmpAot).toString().split('GMT')[0]+' UTC').toISOString().split('.')[0];
    tmpAot = tmpAot.replace("T", "%20");

    updatedAot = tmpAot.replace("%20", " ");
    const params = "?RequestId=2&StartTime=%27" + urlparams.start +
                    "%27&EndTime=%27" + urlparams.end + "%27&AsOfTime=%27" + tmpAot + "%27&ReporyType=" + urlparams.reportType;

    const newURL = base + params;
    props.setUrl(newURL);
    props.setMiniLoaded(true);
    tmpOnClose();
  }

  //  This will render the html page using the DateTimePicker utility
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle  sx={{ m: 0, p: 1.5, bgcolor: '#004A80', color: '#ffe402' }}>Set new timestamp</DialogTitle>
      <div className="text-xs justify-between bg-prob-c-l light:bg-prob-c-d">
        <div className="text-xs justify-between bg-prob-c-l light:bg-prob-c-d mt-2 ml-2" ><p>Report Generated Time:  <strong><i>{generatedAot}</i></strong></p><br/></div>
        <div className="text-xs justify-between bg-prob-c-l light:bg-prob-c-d ml-2" ><p>Last Status Time:  <strong><i>{updatedAot}</i></strong></p><br/></div>
        <table className="text-xs">
          <tbody>
            <tr>
              <td>
                <Dropdown
                  label="Hours: "
                  options={hourOptions}
                  value={hour}
                  onChange={handleHourChange}
                />
              </td>
              <td>
                <Dropdown
                  label=" Mins: "
                  options={minutesOptions}
                  value={minutes}
                  onChange={handleMinutesChange}
                />
              </td>
              <td>
                <Dropdown
                  label=" Secs: "
                  options={secondsOptions}
                  value={seconds}
                  onChange={handleSecondsChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Button onClick={handleDateTimeChange} variant="contained" sx={{
              borderRadius: "25px", ml: 2, boxShadow: 0, background: props.buttonStatus ? 'rgb(107 33 168)' : "rgb(107 33 168)",
              mt: 1
            }}
            >Go</Button>
        <Button onClick={() => window.location.reload(false)} variant="contained" sx={{
              borderRadius: "25px", ml: 3, boxShadow: 0, background: props.buttonStatus ? 'rgb(107 33 168)' : "rgb(107 33 168)",
              mt: 1
            }}
            >Reset</Button><h1 className='mt-3'><b><i>Note: </i></b> The Last status time can go backwards from Report Generated Time only</h1>


      </div>
    </Dialog>
  );
}


// ------------------------- Time Picker Button on Header -------------------------
export function TimePickerDialog(props) {
  
  const [open, setOpen] = React.useState(false);
  const { devArr, now, buttonStatus, setUrl, setMiniLoaded } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div className=''>
      <UpdateIcon 
        variant="outlined" 
        onClick={handleClickOpen}
        sx={{ color: buttonStatus ? "#b0daff" : '#ffe402',  fontSize: 35}}/>
      <TimeWindowSelector devArr={devArr} now={now} 
                          buttonStatus={buttonStatus} setUrl={setUrl}
                          setMiniLoaded={setMiniLoaded}
                          open={open}
                          onClose={handleClose} />
    </div>
  );
} 


export function DisabledSlider(props) {
  const marks = [
    {
      value: 0,
      label: 'Start'
    },
    {
      value: 100,
      label: 'End'
    }
  ];

  return (
    <div className="w-[250px] p-6 mt-6 -mr-9">
      <Slider
        marks={marks}
        value={[0, 100]}
        disabled={true}
        sx={{
          '& .MuiSlider-markLabel': { color: props.buttonStatus ? '#adadad' : 'black' },
          '& .MuiSlider-thumb': {
            height: 20,
            width: 20,
            backgroundColor: props.buttonStatus ? '#adadad' : 'rgb(245 208 254)'
          },
          '& .MuiSlider-track': {
            backgroundColor: props.buttonStatus ? '#1752bf' : 'rgb(107 33 168)',
            color: props.buttonStatus ? '#1752bf' : 'rgb(107 33 168)'
          },
          '& .MuiSlider-rail': { backgroundColor: props.buttonStatus ? '#1752bf' : 'rgb(107 33 168)' },
          '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: '#7c41f2',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
              transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': { transform: 'rotate(45deg)' },
          },
        }}
        valueLabelDisplay="auto"
      />
    </div>
  );
}


export function DarkSwitch(props) {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = React.useState(colorTheme === 'light' ? true : false);

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
    props.setStatus(checked);
  };
  return (
    <DarkModeSwitch
      checked={darkSide}
      onChange={toggleDarkMode}
      size={35}
      sunColor="#ffe402"
      moonColor="#b0daff"
    />
  );
}


export function MakeInfo(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const infoBody = props.infBody;
  const customClass = props.custClass;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'MakeInfo' : undefined;

  return (
    <div className={customClass}>
      <InfoIcon onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2, minWidth: 200, maxWidth: 600 }}>{infoBody}</Typography>
      </Popover>
    </div>
  );
}

export function FilterIndicator(props){

    let alertMssg;
    let isChartFiltered = false;
    const masterContext = useContext(MasterContext);
    const [alrtOpen, setAlrtOpen] = useState(false);
    const filterName = props.filterName;
    const filterNameKey = filterName === null ? [] : Object.values(filterName);
    filterNameKey.forEach(key => {
      isChartFiltered = isChartFiltered || isFilterApplied(masterContext.crossFilter, key);
    });
    const filterFlag = filterName === null ?  masterContext.isReportFiltered : isChartFiltered;
    const filterClassName = props.filterClassName;
    const buttonStatus = masterContext.buttonStatus;
    const isChartFilterIndicator = props.isChartFilterIndicator === undefined ? masterContext: props.isChartFilterIndicator;
    // TODO : Need to make common declaration for Theme color.
    const svgStyle = isChartFilterIndicator ? { color: 'inherit',  fontSize: 25} :
    { color: buttonStatus ? "#b0daff" : '#ffe402',  fontSize: 35} ;


    const handleAlertClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlrtOpen(false);
    };

    const onClickFilter = () =>{
      if (filterNameKey.length > 0){
        masterContext.setCrossFilter({...removeCrossFilter(masterContext.crossFilter, filterNameKey)});
        masterContext.setSelectedCrossFilter(removeSelectedCrossFilter(masterContext.selectedCrossFilter, filterNameKey))
      }
      else {
        masterContext.setCrossFilter({"exclude": {}, "include": {}});
        masterContext.clearCrossFilterSelection();

      }
      setAlrtOpen(true);
    }
   
  if(filterNameKey.length > 0){
    alertMssg = "Cross Filter Cleared for \"" + Object.keys(filterName).toString() + "\"";
  }
  else{
    alertMssg = "Cross Filters cleared";
  }
  // GT: When ToolTip is added there is some problem in Re-render
  // For now, removing it.

   return (
     <>
        {/* Global filter => Active/Inactive filter is indicated
            Chart filter => Only Active filter status is indicated */}
          
          {filterFlag ? 
            <Tooltip title={filterNameKey.length >0 ? "Clear \"" + Object.keys(filterName).toString() + "\" Filter"  :  "Clear Global Filter"} 
              arrow placement="top-end">
                <FilterAltOutlinedIcon className={filterClassName} onClick={onClickFilter} sx={svgStyle}/> 
             </Tooltip>
            : isChartFilterIndicator ? <></> : 
              // <Tooltip title="Global Filter Indicator"
              //   arrow placement="top-end">
                  <FilterAltOffOutlinedIcon className={filterClassName} sx={svgStyle}/>
              // </Tooltip>
             }
        
        <Snackbar open={alrtOpen} autoHideDuration={3000} onClose={handleAlertClose} >
            <Alert severity="success" variant="filled" sx={{ width: '500%' }}>
              {alertMssg}
            </Alert>
        </Snackbar>
      </>
   );
}

// export function OutageTimeline() {
//   return (
//     <React.Fragment>
//       <Timeline position="alternate">
//         <TimelineItem>
//           <TimelineSeparator>
//             <TimelineDot color="primary" />
//             <TimelineConnector color="secondary" />
//           </TimelineSeparator>
//           <TimelineContent>Start</TimelineContent>
//         </TimelineItem>
//         <TimelineItem>
//           <TimelineOppositeContent>
//             End
//           </TimelineOppositeContent>
//           <TimelineSeparator>
//             <TimelineDot color="primary" />
//             <TimelineConnector color="secondary" />
//           </TimelineSeparator>
//           <TimelineContent></TimelineContent>
//         </TimelineItem>
//         <TimelineItem>
//           <TimelineOppositeContent>
//             You're Here
//           </TimelineOppositeContent>
//           <TimelineSeparator>
//             <TimelineDot color="success" />
//           </TimelineSeparator>
//           <TimelineContent>Now</TimelineContent>
//         </TimelineItem>
//       </Timeline>
//     </React.Fragment>
//   );
// }

export const GenerateRequestID = ( report_type) => {
  let reportID = ( report_type === "XiO" ? 1: 2 ).toString() + Math.floor(Math.random() * 9999).toString();
  return reportID;
}

export const MasterContext = createContext();
export const LayoutContext = createContext();