// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import Link from "@mui/material/Link";
// import Typography from "@mui/material/Typography";
import { AuthenticatedTemplate } from "@azure/msal-react";
// import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import MenuButton from "./Menubutton";
// import { Link as RouterLink } from "react-router-dom";
import { convertUnixTime, generateURLParams } from "../pages/incidentReportPages/parse_data";


const NavBar = () => {
    const urlParams = generateURLParams();
    const start = convertUnixTime(urlParams.startObj);
    const end = convertUnixTime(urlParams.endObj);
    const aot = convertUnixTime(urlParams.aotObj);

    return (
        <div sx={{ flexGrow: 1}}>
            <div className="w-full grow shrink flex flex-wrap text-center justify-between sticky bg-navigation-bar text-white p-1.5">
                <img className="h-5 m-2 pt-0.5" 
                        alt="Crestron logo" src={require("./crestron_logo.png")}/>
                <AuthenticatedTemplate>
                    <div className="font-bold">
                        <span style={{fontSize: 15}}  >Evaluation Time : {start} - {end} UTC</span>
                        <span style={{fontSize: 15}} className="block">Report Generated Time : {aot} UTC</span>
                    </div>   
                </AuthenticatedTemplate>
                <div className="flex flex-wrap">
                    <AuthenticatedTemplate>
                        <MenuButton/>
                    </AuthenticatedTemplate>
                    {/* Welcome Name removed as it was causing element justify problems
                        Instead Using User initials in Account Icon */}
                    {/* <WelcomeName /> */}
                    <SignInSignOutButton />
                </div>
                
            </div>

            {/* AppBar was adding complexities in Element Justification
              So removed it and using Basic DOM elements */}

            {/* <AppBar position="relative" className="inline-flex">
                <Toolbar>
    
                    <Typography sx={{ flexGrow: 1}} component={'span'} > */}
                        {/* <Link component={RouterLink} to={"../../home"} color="inherit"
                        variant="h6"> */}
                            {/* <img className="h-5 mt-0.5 grow shrink " 
                        alt="Crestron logo" src={require("./crestron_logo.png")}/> */}
                        {/* </Link> */}
                        {/* <AuthenticatedTemplate>
                            <div >
                                <span style={{fontSize: 11}}  >Evaluation Time : {start} - {end} UTC</span>
                                <span style={{fontSize: 11}} className="block">Report Generated Time : {aot} UTC</span>
                            </div>
                        </AuthenticatedTemplate> */}
                    {/* </Typography>
                    <Typography>
                    <AuthenticatedTemplate>
                            <div >
                                <span style={{fontSize: 11}}  >Evaluation Time : {start} - {end} UTC</span>
                                <span style={{fontSize: 11}} className="block">Report Generated Time : {aot} UTC</span>
                            </div>
                        </AuthenticatedTemplate>
                    </Typography>
                    <AuthenticatedTemplate>
                        <MenuButton/>
                    </AuthenticatedTemplate>
                    <WelcomeName/>
                    <SignInSignOutButton />
                </Toolbar>
            </AppBar> */}
        </div>
    );
};

export default NavBar;