import React, { useContext, useMemo } from 'react';
// import ReportTable from './make_table.js';
import { DonutC, HorizontalBarChart, RecoveryTimeBinChart, ScatterTimeChart, ApimTimeBinChart} from './make_charts.js';
import { TimePickerDialog, DarkSwitch, MakeInfo, FilterIndicator } from './make_comps';
import { generateURLParams, getMultiSelectOptions } from "./parse_data";
import { recoveryTimeBinFrequency, infoObj } from './ui_constants.js';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
// import 'primeflex/primeflex.css';
import {DeviceTable} from './devicetable.js';
import { Tooltip } from '@mui/material';
import { MultiSelect } from 'primereact/multiselect';
import { MasterContext } from './make_comps';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton } from 'primereact/selectbutton';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';


// Filter Values, ToolTip, Colour configurations for Charts
const recoveryFilterName = {'Recovery Status':'RecoveryStatus'};
const offonlbl = ["Not Recovered", "Recovered"];
const offOnColors = ['#bf4a3f', '#4fd67c'];
const offOnNAColours = ['#bf4a3f', '#808080', '#4fd67c']
const probCauseFilterName = {'Probable Causes':'ProbableCause'};
const deviceCountLegend = " Device Count";
const apimFilterName = {'APIM Contact':'ConnectedBackToApim'};
const accountNamefilterName = {'Account Name':'AccountName'};
const modelFilterName = {'Model':'Model'};
const dvFilterName = {'Device Version':'DeviceVersion'};
const recoveryDurationFilterName = {'Time to Recovery (min)':'RecoveryDuration'};
const recoveryTimeBinFilterName = {"Recovery Time Window":   "RecoveryTimeBin"}
const recoveryTimeBinIndexFilterName = {"> Recovery Time Window":   "RecoveryTimeBinIndex"}
const recoveryTimeFilterName = {"Recovery Time": "srcRecoveryTime"}
const parentCidFilterName = {"Parent Cid": "ParentCid" }
const incidentDisconnectToApimFilterName = {"Incident Disconnect to APIM Time Window": "IncidentDisconnectToApimTimeBin"}
const regionFilterName = {"Location": "Location" }
// Multi-Select Options for Cross filter
const recoveryStatusOptions = getMultiSelectOptions({optionList: ["Recovered", "Not Recovered"]});
const apimContactStatusOptions =  getMultiSelectOptions({optionList: ["Yes", "No", "NA"]});
const recoveryTimeBinOptions = getMultiSelectOptions({optionList: recoveryTimeBinFrequency});
const locationOptions = getMultiSelectOptions({optionList: ["E", "W", ""]});
// Time Frequency options are same.
const apimTimeBinOptions = [...recoveryTimeBinOptions];
const anciallaryDeviceFilterName = {"IsAncillary": "IsAncillary"}
const ancillaryDeviceFilterOptions = [
  { name: 'Ancillary', value: [true] },
  { name: 'Non-Ancillary', value: [false] }
]

// Switch and the Outage Report Heading
function RowOne() {
  const masterContext = useContext(MasterContext);
  const now = new Date(Date.now());                           // PROD
  const textColor = masterContext.buttonStatus ? 'white' : 'black';

  const setCrossFilter = (compoundFilterObj, filterName, filterValue, filterOperation="in") => {
    let filterValueList = [];
    if (filterValue !== null){
      filterValue.forEach(filterItem => {
        filterValueList.push(filterItem['name'] === undefined ? filterItem : filterItem['name']);
      });
    }
    const filterAttribute = {[filterName]: {
      'value': filterValueList,
      'operator': filterOperation,
    }}
    if(filterValueList.length > 0){
      compoundFilterObj['add'] = {...compoundFilterObj['add'], ...filterAttribute};
    }
    else {
      compoundFilterObj['remove'] = {...compoundFilterObj['remove'], ...filterAttribute};
    }
  }

  const applyCrossFilter = () => {
    const compoundFilterObj = {'add': {}, 'remove': {}};
    setCrossFilter(compoundFilterObj, accountNamefilterName["Account Name"], masterContext.selectedCrossFilter['AccountName']);
    setCrossFilter(compoundFilterObj, modelFilterName["Model"], masterContext.selectedCrossFilter['Model']);
    setCrossFilter(compoundFilterObj, dvFilterName["Device Version"], masterContext.selectedCrossFilter['DeviceVersion']);
    setCrossFilter(compoundFilterObj, recoveryFilterName["Recovery Status"], masterContext.selectedCrossFilter['RecoveryStatus']);
    setCrossFilter(compoundFilterObj, probCauseFilterName["Probable Causes"], masterContext.selectedCrossFilter['ProbableCause']);
    setCrossFilter(compoundFilterObj, apimFilterName["APIM Contact"], masterContext.selectedCrossFilter['ConnectedBackToApim']);
    setCrossFilter(compoundFilterObj, anciallaryDeviceFilterName["IsAncillary"], masterContext.selectedCrossFilter['IsAncillary']);
    setCrossFilter(compoundFilterObj, recoveryTimeBinFilterName["Recovery Time Window"], masterContext.selectedCrossFilter['RecoveryTimeBin']);
    setCrossFilter(compoundFilterObj, incidentDisconnectToApimFilterName["Incident Disconnect to APIM Time Window"], masterContext.selectedCrossFilter['IncidentDisconnectToApimTimeBin']);
    setCrossFilter(compoundFilterObj, regionFilterName["Location"], masterContext.selectedCrossFilter['Location']);
    // applying cross filter based on cross filter selection in Multi-Select Dropdown
    masterContext.UpdateIncludeCrossFilter({value: compoundFilterObj['add'], addFilter: true, removeValue: compoundFilterObj['remove']});
    // Updating change as false, since all changes are applied.
    masterContext.setCrossFilterChange(false);
  }

  const genericMultiSelectConfig = useMemo(() => {
    return {
      optionLabel: "name" ,
      maxSelectedLabels: 3,
      className: "w-full md:w-20rem",
      fixedPlaceholder: true
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
  <div className="bubs-header"> 
    <div className="flex h-auto">
      <span className="inline-flex ml-5">
          <MenuOutlinedIcon  style={{ color: masterContext.buttonStatus ? "#b0daff" : '#ffe402',  fontSize: 35}}
              onClick={(e) => {masterContext.crossFilterOverlayPanelToggle.current.toggle(e)}}/>
      </span>  
      <div className="w-3/5 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 -mt-1">
        {/* <img className="h-4 mt-2 pl-3 grow shrink" alt="Crestron logo" src={require("./crestron_logo.png")}/> */}
      </div>
      <div className="w-3/5 -mt-1 text-center  grow shrink">
        <div className="text-2xl font-bold text-header-txt-l">XiO Offline - Incident Report </div> 
      </div>
      <div className="relative grow shrink w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 -mt-0.5">

        <span className="absolute inline-flex md:right-11 md:translate-x-0 right-full translate-x-full object-scale-down mr-9">
          <FilterIndicator 
            setCrossFilter={masterContext.setCrossFilter} 
            filterName={null} isChartFilterIndicator={false}
            overlayToggle={masterContext.crossFilterOverlayPanelToggle}/>
        </span>  

        <span className="absolute inline-flex md:right-11 md:translate-x-0 right-full translate-x-full object-scale-down">
          <TimePickerDialog 
            devArr={masterContext.masterObj}
            now={now} buttonStatus={masterContext.buttonStatus}
            setUrl={masterContext.setUrl} setMiniLoaded={masterContext.setMiniLoaded}/>
        </span>  
        <span className="inline-flex float-right right-full object-scale-down">
          <DarkSwitch setStatus={masterContext.setButtonStatus} /></span>
      </div>
    </div>
    </div>  
    <OverlayPanel ref={masterContext.crossFilterOverlayPanelToggle} showCloseIcon
     style={{ width: "580px"}}>
            <div className='inline-flex  '>
        <div className="chartFilter mx-0.5">
            <MultiSelect value={masterContext.selectedCrossFilter['RecoveryStatus']}
              filter
              onChange={(e) => masterContext.UpdateSelectedCrossFilter({'RecoveryStatus': e.value})}
              options={recoveryStatusOptions}
              placeholder="Recovery Status"
              {...genericMultiSelectConfig}/>
        </div>
        <div className="chartFilter mx-0.5">
            <MultiSelect value={masterContext.selectedCrossFilter['ConnectedBackToApim']}
              filter
              onChange={(e) => masterContext.UpdateSelectedCrossFilter({'ConnectedBackToApim': e.value})}
              options={apimContactStatusOptions}
              placeholder="APIM Contact Status"
              {...genericMultiSelectConfig}/>
        </div>
        <div className="chartFilter mx-0.5">
            <MultiSelect value={masterContext.selectedCrossFilter['ProbableCause']}
              filter
              onChange={(e) => masterContext.UpdateSelectedCrossFilter({'ProbableCause': e.value})}
              options={masterContext.donutd.probableCauseOptions}
              placeholder="Probable Cause"
              {...genericMultiSelectConfig}/>
        </div>
      </div>
    <div className='inline-flex mt-1'>
        <div className="chartFilter mx-0.5">
            <MultiSelect
              filter
              panelStyle={{ width: '18rem'}}
              value={masterContext.selectedCrossFilter['Location']}
              onChange={(e) => masterContext.UpdateSelectedCrossFilter({'Location': e.value})}
              // onChange={(e) => setCrossFilter(masterContext.setSelectedCustomers, accountNamefilterName["Account Name"], e.value)}
              options={locationOptions}
              placeholder="Region (Source=CosmosDB)"
              {...genericMultiSelectConfig}/>
        </div>
        <div className="chartFilter mx-0.5">
            <MultiSelect
              filter
              panelStyle={{ width: '18rem'}}
              value={masterContext.selectedCrossFilter['AccountName']}
              onChange={(e) => masterContext.UpdateSelectedCrossFilter({'AccountName': e.value})}
              // onChange={(e) => setCrossFilter(masterContext.setSelectedCustomers, accountNamefilterName["Account Name"], e.value)}
              options={masterContext.donutd.customerOptions}
              placeholder="Customer"
              {...genericMultiSelectConfig}/>
        </div>
        <div className="chartFilter mx-0.5">
            <MultiSelect value={masterContext.selectedCrossFilter['Model']}
              filter
              onChange={(e) => masterContext.UpdateSelectedCrossFilter({'Model': e.value})}
              // onChange={(e) => setCrossFilter(masterContext.setSelectedModels, modelFilterName["Model"], e.value)}
              // onChange={(e) => masterContext.setSelectedCrossFilter.Model(e.value)}
              options={masterContext.donutd.modelOptions}
              placeholder="Model"
              {...genericMultiSelectConfig}/>
        </div>


      </div>
      <div className='inline-flex mt-1'>
      <div className="chartFilter mx-0.5">
            <MultiSelect value={masterContext.selectedCrossFilter['DeviceVersion']}
              filter
              onChange={(e) => masterContext.UpdateSelectedCrossFilter({'DeviceVersion': e.value})}
              // onChange={(e) => masterContext.setSelectedCrossFilter.DeviceVersion(e.value)}
              options={masterContext.donutd.deviceVersionOptions}
              placeholder="Version"
              {...genericMultiSelectConfig}/>
        </div>
          <div className="chartFilter mx-0.5">
              <MultiSelect value={masterContext.selectedCrossFilter['IncidentDisconnectToApimTimeBin']}
                filter
                onChange={(e) => masterContext.UpdateSelectedCrossFilter({'IncidentDisconnectToApimTimeBin': e.value})}
                options={apimTimeBinOptions}
                placeholder="Device Disconnect to APIM Time Bin"
                {...genericMultiSelectConfig}/>
          </div>
      </div>
      <div className='inline-flex mt-1 flex-end'>
        <div className="chartFilter mx-0.5">
              <MultiSelect
                filter
                panelStyle={{ width: '18rem'}}
                value={masterContext.selectedCrossFilter['RecoveryTimeBin']}
                onChange={(e) => masterContext.UpdateSelectedCrossFilter({'RecoveryTimeBin': e.value})}
                options={recoveryTimeBinOptions}
                placeholder="Recovery Time Bin"
                {...genericMultiSelectConfig}/>
          </div>
      </div>
      <div className="mt-1 mx-0.5">
            <SelectButton value={masterContext.selectedCrossFilter['IsAncillary']} className=' text-inherit font-semibold'
              onChange={(e) =>  {
                let selectionValue = e.value;
                // Handling select button de-selection (second click)
                if(selectionValue === null){
                  selectionValue = [];
                }
                masterContext.UpdateSelectedCrossFilter({'IsAncillary': selectionValue});
              }
            }
            optionLabel="name" options={ancillaryDeviceFilterOptions}  />
      </div>
      <div className='mt-1'>
        {/* Filter Clear Button */}
        <Button id="crossFilterMultiSelectButton" size='small'
         disabled={!masterContext.isReportFiltered}
         className={`hover:text-${textColor} text-${textColor} 
         p-button-outline mx-0.5`}
         aria-label="Submit" 
          onClick={() => {
            masterContext.setCrossFilter({"exclude": {}, "include": {}});
            masterContext.clearCrossFilterSelection();
          }}>Clear</Button>
        {/* Filter Apply Button */}
        <Button id="crossFilterMultiSelectButton" size='small'
         disabled={!masterContext.crossFilterChange}
         className={`hover:text-${textColor} text-${textColor} 
         p-button-outline mx-0.5`}
         aria-label="Submit" 
          onClick={applyCrossFilter}>Apply</Button>
      </div>
      </OverlayPanel>
    </>
  );
}


// The bubbles grid for KPIs on data top-0 align-top self-start z-50
function RowTwo(props) {
  const masterContext = useContext(MasterContext);
  // const probC = Object.values(masterContext.donutd.ProbCauses);
  const probCats = Object.keys(masterContext.donutd.ProbCauses);

  const donut1arr = [masterContext.donutd.OutageOff, masterContext.donutd.OutageOn];
  const donut2arr = [masterContext.donutd.NotRecovered, masterContext.donutd.Recovered];

  const percentOffline = ((masterContext.donutd.OutageOff / (masterContext.donutd.OutageOn + masterContext.donutd.OutageOff)) * 100).toFixed(1);
  const percentRecovered = ((masterContext.donutd.Recovered / (masterContext.donutd.Recovered + masterContext.donutd.NotRecovered)) * 100).toFixed(1);

  // const now = new Date(Date.now());                           // PROD

  // APIM contact Status
  const apimContactStats = masterContext.donutd.apimContactStats;

  // Padding with space, to ensure the donuts size match  
  const apimLabels = ['No                    ', 'NA                    ', 'Yes                    '];
  const apimContactStatsList = [apimContactStats['No'], apimContactStats['NA'], apimContactStats['Yes']];
  var percentAPIMContacted = ((apimContactStats['Yes'] / (apimContactStats['Yes'] + apimContactStats['No'])) * 100).toFixed(1);
  percentAPIMContacted = isNaN(percentAPIMContacted) ?  0 : percentAPIMContacted;

  return (
    <div className="flex flex-wrap items-stretch justify-center gap-x-1 gap-y-1 text-1xl font-bold mt-2">
      { /* ONLINE/OFFLINE DONUT */}
      <div className="bubs">
        <h1 className="text-center -mt-2">Claimed Devices Status</h1>
        <FilterIndicator filterClassName='absolute right-8 top-px' 
         isReportFiltered={masterContext.isReportFiltered} 
         setCrossFilter={masterContext.setCrossFilter} crossFilter={masterContext.crossFilter}
         filterName={recoveryFilterName}
         setMiniLoaded={masterContext.setMiniLoaded} />
        <MakeInfo infBody={infoObj.offvOn} custClass="absolute right-2 top-px"/>
        
        <div className="flexx-contnt-donut">
          <DonutC arr={donut1arr} labls={offonlbl} colArr={offOnColors}
            buttonStatus={masterContext.buttonStatus} isRecov={false} filterName={recoveryFilterName}
            isReportFiltered={masterContext.isReportFiltered} 
            UpdateExcludeCrossFilter={masterContext.UpdateExcludeCrossFilter}
            UpdateIncludeCrossFilter={masterContext.UpdateIncludeCrossFilter}
            setMiniLoaded={masterContext.setMiniLoaded}/>

          <h1 className="flex flex-row absolute ml-14 mt-12 text-xs text-donut-txt-l dark:text-main-txt-d">{percentOffline + "%"}<br />Devices<br />Offline<br /></h1>
        </div>

        <div className="flexx-contnt-donut-bottom">
          <div className=" bg-prob-c-l dark:bg-prob-c-d rounded-2xl h-auto w-auto grow shrink mt-6">
            <h3 className="text-ls ml-6 mt p-1">Offline Devices:
              <Tooltip title={masterContext.isReportFiltered  ? "Devices Filtered"  :  ""}  
                 arrow placement="top-end">
                  <span className="text-txt-l dark:text-header-txt-d float-right mr-6">
                    {masterContext.donutd.OutageOff + (masterContext.isReportFiltered ? "*": "")}</span>
              </Tooltip>
              <br />Online Devices: <span className="text-txt-l dark:text-header-txt-d float-right mr-6">{masterContext.donutd.OutageOn}</span>
            </h3>
          </div>
        </div>
      </div>

      { /* RECOVERED DONUT */}
      <div className="bubs">
        <h1 className="text-center -mt-2">Devices Recovery Status</h1>
        <FilterIndicator filterClassName='absolute right-8 top-px' 
         filterName={recoveryFilterName}
         setMiniLoaded={props.setMiniLoaded} />
        <MakeInfo infBody={infoObj.recovered} custClass="absolute right-2 top-px" />
        
        <div className="flexx-contnt-donut">
          <DonutC arr={donut2arr} labls={offonlbl} 
            colArr={offOnColors}
            isRecov={true} filterName={recoveryFilterName}/>

          <h1 className="flex flex-row absolute ml-11 mt-12 text-xs text-donut-txt-l dark:text-main-txt-d">{percentRecovered + "%"}<br />Devices<br />Recovered<br /></h1>
        </div>

        <div className="flexx-contnt-donut-bottom">
          <div className="bg-prob-c-l dark:bg-prob-c-d rounded-2xl h-auto w-auto grow shrink mt-6">
            <h3 className="text-ls ml-6 mt p-1">Recovered:<span className="text-txt-l dark:text-header-txt-d float-right mr-8">{masterContext.donutd.Recovered}</span>
              <br /> Not Recovered: <span className="text-txt-l dark:text-header-txt-d float-right mr-8">{masterContext.donutd.NotRecovered}</span>
            </h3>
          </div>
        </div>
      </div>



      { /* TimePICKER  */}
      {/* <div className="timepicker">
        <MakeInfo infBody={infoObj.slidr} custClass="absolute right-2 top-px" />
        <h1 className="text-center -mt-1 mb-auto ml-auto mr-auto p-2">Time Picker</h1>
        <div className="text-sm justify-between  bg-prob-c-l dark:bg-prob-c-d rounded-3xl p-1 mt-1 ml-3 grow shrink">
          <div className="float-left ">
              <TimeWindowSelector devArr={props.getDevInfo} now={now} buttonStatus={props.buttonStatus}
                setUrl={props.setUrl} setMiniLoaded={props.setMiniLoaded} />
          </div>
        </div>
      </div> */}

      {/* APIM Contact Stats */}
      <div className="bubs">
        <h1 className="text-center -mt-2">APIM Contact Status</h1>
        <FilterIndicator filterClassName='absolute right-8 top-px' 
         isReportFiltered={props.isReportFiltered} 
         setCrossFilter={props.setCrossFilter} crossFilter={props.crossFilter}
         filterName={apimFilterName} 
         setMiniLoaded={props.setMiniLoaded}/>
        <MakeInfo infBody={infoObj.apim} custClass="absolute right-2 top-px" />
        <div className="flexx-contnt-donut">
          <DonutC arr={apimContactStatsList} labls={apimLabels}
            colArr={offOnNAColours}
            filterName={apimFilterName}
            isRecov={true}/>
            <h1 className="flex flex-row flex-wrap absolute ml-9 mt-12 text-xs text-donut-txt-l dark:text-main-txt-d">{percentAPIMContacted + "%"}<br/>APIM Contacted<br /></h1>
        </div>

        <div className="flexx-contnt-donut-bottom">
          <div className="bg-prob-c-l dark:bg-prob-c-d rounded-2xl h-auto w-auto grow shrink mt-6">
            <h3 className="text-ls ml-6 mt p-1">Contacted:<span className="text-txt-l dark:text-header-txt-d float-right mr-8">{apimContactStats['Yes']}</span>
              <br /> Not Contacted: <span className="text-txt-l dark:text-header-txt-d float-right mr-8">{apimContactStats['No']}</span>
            </h3>
          </div>
        </div>
      </div>

      { /* PROBABLE CAUSES */}
      {/* <div className="bubs-prob-cause">
        <MakeInfo infBody={infoObj.probC} custClass="absolute right-2 top-px" />
        <h1 className="text-center -mt-2">Probable Causes</h1>
        <div className="text-sm justify-between w-full h-full bg-prob-c-l dark:bg-prob-c-d rounded-3xl p-1 -mt-1">
          <ArrangeProbCauses probKeys={probCats} probVals={probC} />
        </div>
      </div> */}
     <div className="bubs">
        <h1 className="text-center -mt-2">Probable causes</h1>
        <FilterIndicator filterClassName='absolute right-3 top-px' 
          filterName={probCauseFilterName}/>
        <HorizontalBarChart 
          arr={masterContext.donutd.ProbCauses} labls={probCats} filterName={probCauseFilterName}
          tooltipName={deviceCountLegend}
          additionalClassProp="h-full"/>
      </div>

      <div className="bubs">
        <h1 className="text-center -mt-2">Ancillary Devices by Parent Cid</h1>
        <FilterIndicator filterClassName='absolute right-3 top-px' 
          filterName={parentCidFilterName} />
        {/* <FilterIndicator filterClassName='absolute right-3 top-px' 
          isReportFiltered={masterContext.isReportFiltered} 
          setCrossFilter={masterContext.setCrossFilter} crossFilter={masterContext.crossFilter}
          filterName={parentCidFilterName}
          setMiniLoaded={masterContext.setMiniLoaded} /> */}
        {Object.keys(masterContext.donutd.anciallaryDeviceInfo.ancillaryDeviceStats).length > 0 ? 
        <HorizontalBarChart 
          arr={masterContext.donutd.anciallaryDeviceInfo.ancillaryDeviceStats} 
          labls={Object.keys(masterContext.donutd.anciallaryDeviceInfo.ancillaryDeviceStats)}
          childDeviceCount={masterContext.donutd.anciallaryDeviceInfo.childDeviceCount}
          otherParentCid={masterContext.donutd.anciallaryDeviceInfo.otherParentCid}
          filterName={parentCidFilterName}
          tooltipName={deviceCountLegend}
          additionalClassProp="h-full"/> :
        <>
          <div className=' text-center m-20 font-thin' >No Parent Devices found</div>
        </> }
      </div>

  </div>
  )
}


// KPI Charts for Top N Model, Customer, DeviceVersion for affected
function RowThree(props) {
  const masterContext = useContext(MasterContext);
  // data for customer
  const donutCustTopStats = masterContext.donutd.topCustomerStats;
  const topCustomerName = Object.keys(donutCustTopStats);

  // data for model
  const donutModelTopStats = masterContext.donutd.topModelStats;
  const topModelNames = Object.keys(donutModelTopStats);
  
  // data for device versions

  const donutDeviceVersionTopStats = masterContext.donutd.topDeviceVersionStats;
  const topDeviceVersions = Object.keys(donutDeviceVersionTopStats);

  return (
    <div className="flex flex-wrap items-stretch justify-center gap-x-1 gap-y-1 text-sm font-bold mt-1">
      {/* Top 10 Customer */}
      <div className="bubskpi">
        <h1 className="text-center -mt-2">{`Top 10 Customers (${masterContext.recoveryStatus})`}</h1>
        <FilterIndicator filterClassName='absolute right-3 top-px' 
         filterName={accountNamefilterName}/>
        <div className="flexx-contnt-chart">
          <HorizontalBarChart arr={donutCustTopStats} labls={topCustomerName}
          filterName={accountNamefilterName} tooltipName={deviceCountLegend}  />
        </div>
      </div>
      {/* Top 10 Models */}
      <div className="bubskpi">
        <h1 className="text-center -mt-2">{`Top 10 Models (${masterContext.recoveryStatus})`}</h1>
        <FilterIndicator filterClassName='absolute right-3 top-px' 
         filterName={modelFilterName} />
        <div className="flexx-contnt-chart">
          <HorizontalBarChart arr={donutModelTopStats} labls={topModelNames} 
          filterName={modelFilterName} tooltipName={deviceCountLegend} />
        </div>
      </div>
      {/* Top 10 Device Versions */}
      <div className="bubskpi">
        <h1 className="text-center -mt-2">{`Top 10 Versions (${masterContext.recoveryStatus})`}</h1>
        <FilterIndicator filterClassName='absolute right-3 top-px' 
         filterName={dvFilterName} />
        <div className="flexx-contnt-chart">
          <HorizontalBarChart 
          arr={donutDeviceVersionTopStats} labls={topDeviceVersions}
          filterName={dvFilterName} tooltipName={deviceCountLegend}/>
        </div>
      </div>

    </div>
  )
}


// KPI Charts for Top N Model, Customer, DeviceVersion for Recovered
function RowFour(props) {
  const masterContext = useContext(MasterContext);
  // data for customer
  const donutRecCustTopStats = masterContext.donutd.topCustomerRecPercentage;
  const topRecCustomerName = Object.keys(donutRecCustTopStats);
  const legendName = " Recovered %"

  // data for model
  const donutRecModelTopStats = masterContext.donutd.topModelRecPercentage;
  const topRecModelNames = Object.keys(donutRecModelTopStats);
  
  // data for device versions
  const donutRecDeviceVersionTopStats = masterContext.donutd.topDeviceRecStatsPercentage;
  const topRecDeviceVersions = Object.keys(donutRecDeviceVersionTopStats);
  
  return (
    <div className="flex flex-wrap items-stretch justify-center gap-x-1 gap-y-1 text-sm font-bold mt-1">
      {/* Top 10 Customer */}
      <div className="bubskpi">
        <h1 className="text-center -mt-2">Top Customers Recovery Status</h1>
        <FilterIndicator filterClassName='absolute right-3 top-px' 
         filterName={accountNamefilterName} />
        <div className="flexx-contnt-chart">
        </div>
          <HorizontalBarChart arr={donutRecCustTopStats} labls={topRecCustomerName}
          filterName={accountNamefilterName} tooltipName={legendName} />
      </div>
      {/* Top 10 Models */}
      <div className="bubskpi">
        <h1 className="text-center -mt-2">Top Models Recovery Status</h1>
        <FilterIndicator filterClassName='absolute right-3 top-px' 
         filterName={modelFilterName} />
        <div className="flexx-contnt-chart">
          <HorizontalBarChart arr={donutRecModelTopStats} labls={topRecModelNames} 
          filterName={modelFilterName} tooltipName={legendName}/>
        </div>
      </div>
      {/* Top 10 Models */}
      <div className="bubskpi">
        <h1 className="text-center -mt-2">Top Versions Recovery Status</h1>
        <FilterIndicator filterClassName='absolute right-3 top-px' 
         filterName={dvFilterName} />
        <div className="flexx-contnt-chart">
          <HorizontalBarChart 
          arr={donutRecDeviceVersionTopStats} labls={topRecDeviceVersions}
          filterName={dvFilterName} tooltipName={legendName}/>
        </div>
      </div>

    </div>
  )
}


// KPI Charts for Device Recovery Performance
function RowFive(props) {
  const masterContext = useContext(MasterContext);
  const urlParams = generateURLParams();

  const getTimeScatterPoints = (dataPoints) => {
    let scatterPoints = [];
    let timeDuration = Object.keys(dataPoints);
    timeDuration.sort(function(a,b) { return a - b;});
    for (let i=0;i< timeDuration.length; i++){
      scatterPoints.push({'x': dataPoints[timeDuration[i]] , 'y': timeDuration[i]});
    }
    return scatterPoints;
  
  }
  const scatterTimeData = getTimeScatterPoints(masterContext.donutd.recoveryTimeDurationSpread);

  return (
    <div className="flex flex-wrap items-stretch justify-center gap-x-1 gap-y-1 text-sm font-bold mt-1">
      <div className="bubskpibig"> 
          <ApimTimeBinChart incidentTimetoApim={masterContext.donutd.apimTimeStatsMins}
            lastTimetoApim={masterContext.donutd.lastapimTimeStatsMins}
            // notRecoveredDeviceTimeStatus={props.donutD.notRecoveryTimeStatsMins} 
            // affectedDeviceTimeStatus={props.donutD.affectedTimeStatsMins}
            buttonStatus={masterContext.buttonStatus} 
            incidentDisconnectToApimFilterName={incidentDisconnectToApimFilterName}
            additionalClassProp="h-72">
          </ApimTimeBinChart>
        </div>
        <div className="bubskpibig"> 
          <RecoveryTimeBinChart recoveredDeviceTimeStatus={masterContext.donutd.recoveryTimeStatsMins}
            notRecoveredDeviceTimeStatus={masterContext.donutd.notRecoveryTimeStatsMins} 
            affectedDeviceTimeStatus={masterContext.donutd.affectedTimeStatsMins}
            recoveryTimeBinFilterName={recoveryTimeBinFilterName}
            recoveryFilterName={recoveryFilterName}
            recoveryTimeBinIndexFilterName={recoveryTimeBinIndexFilterName}
            additionalClassProp="h-72">
          </RecoveryTimeBinChart>
        </div>
        <div className="bubskpibig">
          {Object.keys(scatterTimeData).length > 0 ? 
            <ScatterTimeChart data={scatterTimeData}
              recoveryTimeFilterName={recoveryTimeFilterName}
              recoveryDurationFilterName={recoveryDurationFilterName}
              xMinTime={urlParams.startObj}
              xMaxTime={urlParams.endObj}
              xMaxZoomTime={urlParams.aotObj} 
              yMaxZoomDuration={masterContext.donutd.maxRecoveryTimeRecordedMin}
              additionalClassProp="h-72"
            />: 
            // If all devices from Filter result is Not recovered, then displaying below message
            <>
              <h1 className="text-center -mt-2">Device Recovery Timeline</h1>
              <div className=' text-center m-20 font-thin' >No devices found</div>
            </>
          }
        </div>
    </div>
  )
}


// // KPI Charts for Ancillary Devices
// function RowSix() {
//   const masterContext = useContext(MasterContext);
//   return (
//     <div className="flex flex-wrap items-stretch justify-center gap-x-1 gap-y-1 text-sm font-bold mt-1">
//       <div className="bubskpibig justify-between">
//         <h1 className="text-center -mt-2">Ancillary Devices by Parent Cid</h1>
//         <FilterIndicator filterClassName='absolute right-3 top-px' 
//           filterName={parentCidFilterName} />
//         {/* <FilterIndicator filterClassName='absolute right-3 top-px' 
//           isReportFiltered={masterContext.isReportFiltered} 
//           setCrossFilter={masterContext.setCrossFilter} crossFilter={masterContext.crossFilter}
//           filterName={parentCidFilterName}
//           setMiniLoaded={masterContext.setMiniLoaded} /> */}
//         {Object.keys(masterContext.donutd.anciallaryDeviceInfo.ancillaryDeviceStats).length > 0 ? 
//         <HorizontalBarChart 
//           arr={masterContext.donutd.anciallaryDeviceInfo.ancillaryDeviceStats} 
//           labls={Object.keys(masterContext.donutd.anciallaryDeviceInfo.ancillaryDeviceStats)}
//           childDeviceCount={masterContext.donutd.anciallaryDeviceInfo.childDeviceCount}
//           otherParentCid={masterContext.donutd.anciallaryDeviceInfo.otherParentCid}
//           filterName={parentCidFilterName}
//           tooltipName={deviceCountLegend}
//           /> :
//         <>
//           <div className=' text-center m-20 font-thin' >No Parent Devices found</div>
//         </> }
//       </div>
//       <div className="bubskpibig">
//         <div className=' text-center m-20 font-thin' >Upcoming KPI..</div>
//       </div>
//       <div className="bubskpibig">
//         <div className=' text-center m-20 font-thin' >Upcoming KPI..</div>
//       </div>
//     </div>
//   )
// }


// Master Function for calling in everything
export default function RenLayout() {

  const masterContext = useContext(MasterContext);
  const devicesArr = masterContext.devArr;
  if (devicesArr === undefined) {
    return <h1>Failed to Generate the Report.</h1>;
  }
  else if (devicesArr.length <= 2){
    console.log(`FETCHED DATA: ${devicesArr}`);
    return <h1>{devicesArr[0]}</h1>;
  }
  return (
    <>
      <div className="grow shrink p-6 bg-main-bg-l dark:bg-main-bg-d text-main-txt-l dark:text-main-txt-d -mt-12">
        <RowOne />
        <RowTwo  />
        <RowThree />
        {/* If recovery filter is applied, then not showing Recovery status charts */}
        {!masterContext.isRecoveryFilterApplied && <RowFour  />}
        <RowFive />
        {/* <RowSix  /> */}

        {/* OLD MUI Table - Deprecated, not in USE */}
        {/* <ReportTable mainObj={masterObj} buttonStatus={buttonStatus} getFilter={getFilter} 
          getFilterName={getFilterName} tableRows={props.tableRows} /> */}
      </div>
      <div className="relative overflow-auto pr-6 pl-6 pb-6 bg-main-bg-l dark:bg-main-bg-d text-main-txt-l dark:text-main-txt-d">
        <div className="w-full text-sm font-normal">
          <DeviceTable />
        </div>
      </div>
    </>
  )
}
